<template>
  <l-marker :_markerid="marker.id" class="leaflet-marker" ref="marker" :lat-lng="transformPositions(marker.position.x, marker.position.y)" @click="() => { markerClick(marker); }">
    <l-icon
      :icon-size="[30, 30]"
      :icon-anchor="[0, 0]"
      :popupAnchor="[16, 0]"
      :tooltipAnchor="[0, 0]"
      :iconUrl="'/assets/device_tracking/placeholder.png'" 
      :shadowUrl="'/assets/device_tracking/placeholder.png'" 
      class-name="markerIcon_old"
    >
      <button :class="isSelected ? 'btn btn-secondary btn-circle btn-sm opacity-60 hover:opacity-100 markerSelected' : 'btn btn-secondary btn-circle btn-sm opacity-60 hover:opacity-100'">
        <svg v-if="marker.assetType == 'MOBILE_PHONE'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
          <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
        </svg>
        <svg v-if="marker.assetType == 'RFID_INSTRUMENT'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 3v1.5M4.5 8.25H3m18 0h-1.5M4.5 12H3m18 0h-1.5m-15 3.75H3m18 0h-1.5M8.25 19.5V21M12 3v1.5m0 15V21m3.75-18v1.5m0 15V21m-9-1.5h10.5a2.25 2.25 0 002.25-2.25V6.75a2.25 2.25 0 00-2.25-2.25H6.75A2.25 2.25 0 004.5 6.75v10.5a2.25 2.25 0 002.25 2.25zm.75-12h9v9h-9v-9z" />
        </svg>
      </button>
    </l-icon>
    <l-popup class="leaflet-nrg-popup" ref="popup" @ready="addEventListeners" :options="{closeOnClick:false}">
      <div class="card bg-base-100 w-full shadow-xl">
        <figure class="px-5 pt-5">
          <label class=" btn btn-secondary btn-circle">
            <svg v-if="marker.assetType == 'MOBILE_PHONE'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8">
              <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
            </svg>
            <svg v-if="marker.assetType == 'RFID_INSTRUMENT'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8">
              <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 3v1.5M4.5 8.25H3m18 0h-1.5M4.5 12H3m18 0h-1.5m-15 3.75H3m18 0h-1.5M8.25 19.5V21M12 3v1.5m0 15V21m3.75-18v1.5m0 15V21m-9-1.5h10.5a2.25 2.25 0 002.25-2.25V6.75a2.25 2.25 0 00-2.25-2.25H6.75A2.25 2.25 0 004.5 6.75v10.5a2.25 2.25 0 002.25 2.25zm.75-12h9v9h-9v-9z" />
            </svg>
          </label>
        </figure>
        <div class="divider text-[#a6adba] text-sm">{{ marker.assetType }}</div>
        <div class="card-body items-center text-left p-0 pb-5 pl-5 pr-5">
          <div class="flex w-full text-lg">
            <div class="flex-0 w-16 mr-2 text-xs text-right text-[#a6adbb]">{{ $t("message.name") }}: </div>
            <strong class="flex-1 badge badge-secondary">{{ marker.displayName }}</strong>
          </div>
          <div class="flex w-full text-lg">
            <div class="flex-0 w-16 mr-2 text-xs text-right text-[#a6adbb]">{{ $t("message.device") }}: </div>
            <strong class="flex-1 badge badge-secondary">{{ marker.hostMac }}</strong>
          </div>
          <div class="flex w-full text-lg">
            <div class="flex-0 w-16 mr-2 text-xs text-right text-[#a6adbb]">{{ $t("message.position") }}: </div>
            <strong class="flex-1 badge badge-secondary">{{ Math.round(marker.position.x) }}, {{ Math.round(marker.position.y) }}</strong>
          </div>
        </div>
      </div>
    </l-popup>
    <l-tooltip class="leaflet-nrg-tooltip" ref="tooltip" :options="{direction:'auto', opacity:1, sticky: true, permanent: false}" v-if="!isSelected">
      <div class="card bg-base-100 w-full shadow-xl">
        <figure class="px-5 pt-5">
          <label class=" btn btn-secondary btn-circle">
            <svg v-if="marker.assetType == 'MOBILE_PHONE'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8">
              <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
            </svg>
            <svg v-if="marker.assetType == 'RFID_INSTRUMENT'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8">
              <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 3v1.5M4.5 8.25H3m18 0h-1.5M4.5 12H3m18 0h-1.5m-15 3.75H3m18 0h-1.5M8.25 19.5V21M12 3v1.5m0 15V21m3.75-18v1.5m0 15V21m-9-1.5h10.5a2.25 2.25 0 002.25-2.25V6.75a2.25 2.25 0 00-2.25-2.25H6.75A2.25 2.25 0 004.5 6.75v10.5a2.25 2.25 0 002.25 2.25zm.75-12h9v9h-9v-9z" />
            </svg>
          </label>
        </figure>
        <div class="divider text-[#a6adba] text-sm">{{ marker.assetType }}</div>
        <div class="card-body items-center text-left p-0 pb-5 pl-5 pr-5">
          <div class="flex w-full text-lg">
            <div class="flex-0 w-16 mr-2 text-xs text-right text-[#a6adbb]">{{ $t("message.name") }}: </div>
            <strong class="flex-1 badge badge-secondary">{{ marker.displayName }}</strong>
          </div>
          <div class="flex w-full text-lg">
            <div class="flex-0 w-16 mr-2 text-xs text-right text-[#a6adbb]">{{ $t("message.device") }}: </div>
            <strong class="flex-1 badge badge-secondary">{{ marker.hostMac }}</strong>
          </div>
          <div class="flex w-full text-lg">
            <div class="flex-0 w-16 mr-2 text-xs text-right text-[#a6adbb]">{{ $t("message.position") }}: </div>
            <strong class="flex-1 badge badge-secondary">{{ Math.round(marker.position.x) }}, {{ Math.round(marker.position.y) }}</strong>
          </div>
        </div>
      </div>
    </l-tooltip>
  </l-marker>
</template>

<script>
import { LPopup, LIcon, LTooltip, LMarker } from '@vue-leaflet/vue-leaflet';

export default {
  name: 'MapMarker',
  props: {
    marker: {
      type: Object,
      default: () => {}
    },
    currentFloorGeometry: {
      type: Object,
      default: () => {}
    },
    mapDimensionsX: {
      type: Number,
      default: null
    },
    mapDimensionsY: {
      type: Number,
      default: null
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    selectMarker: {
      type: Function,
      default: () => {}
    },
    deselectMarker: {
      type: Function,
      default: () => {}
    }
  },
  watch: {
    isSelected() {
      var vm = this;
      if(this.isSelected) {
        this.$nextTick(function() {
          vm.$refs.marker.leafletObject.openPopup();
        })
      }
      else {
        this.$nextTick(function() {
          vm.$refs.marker.leafletObject.closePopup();
        })
      }
    }
  },
  mounted() {
    if(this.isSelected) {
      var vm = this;
      this.$nextTick(function() {
        vm.$refs.marker.leafletObject.openPopup();
      })
    }
  },
  components: {
    LPopup,
    LIcon,
    LTooltip,
    LMarker
  },
  data() {
    return {
      eventListenersSet: false,
      tooltipOpened: false
    }
  },
  updated() {
    var vm = this;
    if(this.isSelected) {
      vm.$refs.marker.leafletObject.openPopup();
    }
  },
  methods: {
    addEventListeners() {
      var vm = this;
      if(!vm.eventListenersSet) {
        this.$refs.popup.leafletObject.on('remove', function() {
          vm.deselectMarker(vm.marker.id);
        });

        vm.eventListenersSet = true;
      }
    },
    onPopupClose(e) {
      console.log(e)
    },
    markerClick(marker) {
      var vm = this;
      vm.selectMarker(marker);
    },
    transformPositions(x, y) {
      var xRatio = this.mapDimensionsX / this.currentFloorGeometry.width;
      var yRatio = this.mapDimensionsY / this.currentFloorGeometry.length;

      x = x*xRatio;
      y = y*yRatio;

      var transponedY = this.mapDimensionsY-y;
      var transponedX = x;

      return window.L.latLng(transponedY, transponedX)
    }
  }
}
</script>

<style>
.leaflet-tooltip-top:before,
.leaflet-tooltip-top:after,
.leaflet-tooltip-bottom:before,
.leaflet-tooltip-bottom:after,
.leaflet-tooltip-left:before,
.leaflet-tooltip-left:after,
.leaflet-tooltip-right:before,
.leaflet-tooltip-right:after {
  display: none;
}

.leaflet-popup-tip {
  background: #1f242dd6 !important;
}

.leaflet-popup-content-wrapper {
  background: #1f242dd6 !important;
}

.leaflet-nrg-popup .card {
  background-color: transparent !important;
}

.leaflet-popup-content {
  margin: 0px !important;
}

.leaflet-nrg-tooltip .card {
  background: #1f242dd6 !important;
}

.marker-cluster-medium {
  background-color: #2a303c30 !important;
  color: white;
}

.marker-cluster-medium div {
  background-color: #2a303c9c !important;
}

.marker-cluster-small {
  background-color: #e1833047 !important;
  color: white;
}

.marker-cluster-small div {
  background-color: --nrg-primary !important;
}

.markerSelected {
  animation: pulse 2s infinite;
}

</style>
