const messages =
{
    en: {
        message: {
            resolution: 'Resolution',
            minutes: 'minutes',
            day: 'day',
            hour: 'hour',
            levels: 'Levels',
            infos: 'Infos',
            currentPresenceGist: 'Current presence',
            numberOfWorkplaces: 'Number of workplaces',
            sqm: 'sqm',
            externalIdentifier: 'External Identifier',
            regenerateApiKeyConfirmation: 'Are you sure you want to regenerate the API key?',
            regenerateApiKeyConfirmationCancel: 'Cancel',
            regenerateApiKeyConfirmationOk: 'Yes',
            generateApiKeyButtonTitle: 'New API key',
            copy: 'Copy',
            missingApiKey: 'API key is not generated yet',
            copiedToClipboard: 'Copied to clipboard',
            generateApiKeyTitle: 'API key',
            generateApiKeyExample: 'Example',
            noAssignedDevice: 'No assigned device',
            backToTheMap: 'Back to the map',
            error: 'Error',
            invalidMapView: 'The data of the loaded map is incorrect',
            loading: 'Loading...',
            loadedAreaHasNoCoordinates: 'The loaded area does not have geographical coordinates.',
            device: 'Device',
            noSubArea: 'There are no subareas attached to this area.',
            lidarView: 'LIDAR View',
            select: 'Select',
            close: 'Close',
            rain: 'Rain: ',
            displayName: 'Display Name',
            deviceType: 'Device Type',
            choose: 'Choose',
            adapterType: 'Adapter Type',
            adapterConfig: 'Adapter Configuration',
            save: 'Save',
            test: 'Test',
            confirmRemove: 'Are you sure that you want to remove this device?',
            confirmRemoveScenario: 'Are you sure that you want to remove this scenario?',
            noDevice: 'There is no device added right now',
            listOfDevices: 'List of Devices',
            addDevice: 'Add Device',
            editDevice: 'Edit Device',
            addNew: 'Add new device',
            noFloorsFound: 'There are no floors for this building.',
            editZone: 'Edit Zone',
            designation: 'Designation',
            zoneName: 'The name of the zone',
            numberOfPossibleWorkplaces: 'Number of possible workplaces',
            floorSpace: 'Floorspace',
            zoneType: 'Zone type',
            zoneGroups: 'Zone groups',
            isActive: 'Active?',
            successfulSave: 'Successful save...',
            notSuccessfulSave: 'Save is not successful...',
            searchingDevice: 'Searching Device...',
            noResults: 'The search has no result...',
            atleastOneCharacter: 'Atleast one character...',
            profile: 'Profile',
            logoutInProgress: 'Logout in progress...',
            name: 'Name',
            position: 'Position',
            breakdown: 'Breakdown',
            temperatureData: 'TEMPERATURE DATA',
            populationData: 'POPULATION DATA',
            errorAlt: 'A page refresh may solve the problem',
            permissionDenied: 'Permission Denied',
            goBack: 'Go to the previous page',
            sessionExpired: 'The session expired, please try to log in again!',
            loginAgain: 'I will log in again',
            unauthorizedTenant: 'The usage of this application is not allowed for your company!',
            saveError: 'Errors occured during save',
            anonymus: 'Anonymus',
            deleteSuccessful: 'Delete successful',
            deleteNotSuccessful: 'Delete was not successful',
            noViewImage: 'There is no available image for this view!',
            deviceNamePlaceholder: 'eg. Device Name',
            yes: 'Confirm',
            no: 'Deny',
            zoneNamePlaceholder: 'eg. Corridor',
            egNumPlaceholder: 'eg. 100',
            search: 'Search',
            zoneMap: 'Zone Map',
            temperatureMap: 'Temperature Map',
            deviceMap: 'Device Map',
            zoneAdmin: 'Zone Admin',
            system: 'System',
            scenarios: 'Scenarios',
            reports: 'Reports',
            newScenario: 'New scenario',
            noScenarioAddedCurrently: 'No scenarios configured yet.',
            selectOutput: 'Select Output',
            selectInputs: 'Select Inputs',
            selectTime: 'Set start time',
            editScenario: 'Edit Scenario',
            data: 'Data',
            responders: 'Scenarios',
            syncSites: 'Sync',
            outgoingIPAddress: "Outgoing IP address",
            chooseLang: 'Choose Language: ',
            hungarian: 'Hungarian',
            english: 'English',
            downloadEkrReport: 'Download EKR Report',
        }
    },
    hu: {
        message: {
            resolution: 'Bontás',
            minutes: 'perc',
            day: 'nap',
            hour: 'óra',
            levels: 'Szintek',
            infos: 'Infók',
            currentPresenceGist: 'Aktuális jelenlét',
            numberOfWorkplaces: 'M. áll száma',
            sqm: 'Terület (m2)',
            externalIdentifier: 'Külső azonosító',
            regenerateApiKeyConfirmation: 'Biztosan újra generálja az API kulcsot?',
            regenerateApiKeyConfirmationCancel: 'Mégse',
            regenerateApiKeyConfirmationOk: 'Igen',
            generateApiKeyButtonTitle: 'Új API kulcs generálása',
            copy: 'Másolás',
            missingApiKey: 'API kulcs még nincs generálva',
            copiedToClipboard: 'Másolva a vágólapra',
            generateApiKeyTitle: 'API kulcs',
            generateApiKeyExample: 'Példa',
            noAssignedDevice: 'Nincs hozzárendelt eszköz',
            backToTheMap: 'Vissza a térképhez',
            error: 'Hiba történt',
            invalidMapView: 'A betöltött térkép adatai nem megfelelőek.',
            loading: 'Betöltés...',
            loadedAreaHasNoCoordinates: 'A betöltött területnek nincsenek meghatározott földrajzi koordinátái.',
            device: 'Eszköz',
            noSubArea: 'Ehhez a területhez nem tartozik alterület.',
            lidarView: 'LIDAR Nézet',
            select: 'Kiválasztás!',
            close: 'BEZÁRÁS',
            rain: 'Csapadék: ',
            displayName: 'Megjelenítendő név',
            deviceType: 'Eszköz típusa',
            choose: 'Válasszon',
            adapterType: 'Adapter típusa',
            adapterConfig: 'Adapter konfiguráció',
            save: 'Mentés',
            test: 'Teszt',
            confirmRemove: 'Biztos benne, hogy törli az eszközt?',
            confirmRemoveScenario: 'Biztos benne, hogy törli a scenariot?',
            noDevice: 'Jelenleg nincs eszköz hozzáadva',
            listOfDevices: 'Eszközök listája',
            addDevice: 'Eszköz hozzáadása',
            editDevice: 'Eszköz szerkesztése',
            addNew: 'Új hozzáadása',
            noFloorsFound: 'Ehhez az épülethez nem találhatók szintek.',
            editZone: 'Zóna szerkesztése',
            designation: 'Megnevezés',
            zoneName: 'A zóna neve',
            numberOfPossibleWorkplaces: 'Lehetséges m.áll. száma',
            floorSpace: 'Alapterület',
            zoneType: 'Zóna típusa',
            zoneGroups: 'Zónacsoportok',
            isActive: 'Aktív?',
            successfulSave: 'Sikeres mentés...',
            notSuccessfulSave: 'Sikertelen mentés...',
            searchingDevice: 'Eszköz keresése...',
            noResults: 'Nincs találat...',
            atleastOneCharacter: 'Min. 1 karakter...',
            profile: 'Profil',
            logoutInProgress: 'Kijelentkezés folyamatban...',
            name: 'Név',
            position: 'Pozíció',
            breakdown: 'Bontás',
            temperatureData: 'HŐMÉRSÉKLETI ADATOK',
            populationData: 'LÉTSZÁM ADATOK',
            errorAlt: 'Az oldal frissítése talán megoldja a problémát',
            permissionDenied: 'Hozzáférés megtagadva',
            goBack: 'Visszalépés',
            sessionExpired: 'A munkamenet lejárt, jelentkezzen be újra!',
            loginAgain: 'Belépek újra',
            unauthorizedTenant: 'Az Ön szervezete számára nem engedélyezett az alkalmazás használata!',
            saveError: 'A mentés közben hibák léptek fel.',
            anonymus: 'Névtelen',
            deleteSuccessful: 'Sikeres törlés...',
            deleteNotSuccessful: 'Sikertelen törlés...',
            noViewImage: 'A nézethez nincs elérhető képi információ!',
            deviceNamePlaceholder: 'pl. Eszköz neve',
            yes: 'Igen',
            no: 'Mégse',
            zoneNamePlaceholder: 'pl. Folyosó',
            egNumPlaceholder: 'pl. 100',
            search: 'Keresés',
            zoneMap: 'Zónatérkép',
            temperatureMap: 'Hőmérsékleti térkép',
            deviceMap: 'Eszköztérkép',
            zoneAdmin: 'Zóna admin',


            system: 'Rendszer',
            scenarios: 'Szkenáriók',
            reports: 'Riportok',
            newScenario: 'Új szkenárió',
            noScenarioAddedCurrently: 'Jelenleg nincs scenario hozzáadva.',
            selectOutput: 'Kimenet kiválasztása',
            selectInputs: 'Bemenetek kiválasztása',
            selectTime: 'Időpont beállítása',
            editScenario: 'Szkenárió szerkesztése',
            data: 'Adatok',
            responders: 'Szkenáriók',
            syncSites: 'Frissítése',
            outgoingIPAddress: 'Kimenő IP cím',
            chooseLang: 'Válasszon nyelvet: ',
            hungarian: 'Magyar',
            english: 'Angol',
            downloadEkrReport: 'EKR riport letöltése',
        }
    },
    es: {
        message: {
            resolution: 'Resolución',
            minutes: 'minutos',
            day: 'día',
            hour: 'hora',
            levels: 'Niveles',
            infos: 'Informaciones',
            currentPresenceGist: 'Presencia actual',
            numberOfWorkplaces: 'Número de lugares de trabajo',
            sqm: 'm²',
            externalIdentifier: 'Identificador Externo',
            regenerateApiKeyConfirmation: '¿Está seguro de que desea regenerar la clave API?',
            regenerateApiKeyConfirmationCancel: 'Cancelar',
            regenerateApiKeyConfirmationOk: 'Sí',
            generateApiKeyButtonTitle: 'Nueva clave API',
            copy: 'Copiar',
            missingApiKey: 'La clave API no se ha generado aún',
            copiedToClipboard: 'Copiado al portapapeles',
            generateApiKeyTitle: 'Clave API',
            generateApiKeyExample: 'Ejemplo',
            noAssignedDevice: 'No hay dispositivo asignado',
            backToTheMap: 'Volver al mapa',
            error: 'Error',
            invalidMapView: 'Los datos del mapa cargado son incorrectos',
            loading: 'Cargando...',
            loadedAreaHasNoCoordinates: 'El área cargada no tiene coordenadas geográficas.',
            device: 'Dispositivo',
            noSubArea: 'No hay subáreas adjuntas a esta área.',
            lidarView: 'Vista LIDAR',
            select: 'Seleccionar',
            close: 'Cerrar',
            rain: 'Lluvia: ',
            displayName: 'Nombre para mostrar',
            deviceType: 'Tipo de dispositivo',
            choose: 'Elegir',
            adapterType: 'Tipo de adaptador',
            adapterConfig: 'Configuración del adaptador',
            save: 'Guardar',
            test: 'Probar',
            confirmRemove: '¿Está seguro de que desea eliminar este dispositivo?',
            confirmRemoveScenario: '¿Está seguro de que desea eliminar este escenario?',
            noDevice: 'No hay dispositivo agregado actualmente',
            listOfDevices: 'Lista de dispositivos',
            addDevice: 'Agregar dispositivo',
            editDevice: 'Editar dispositivo',
            addNew: 'Agregar nuevo dispositivo',
            noFloorsFound: 'No hay pisos para este edificio.',
            editZone: 'Editar zona',
            designation: 'Designación',
            zoneName: 'Nombre de la zona',
            numberOfPossibleWorkplaces: 'Número de posibles lugares de trabajo',
            floorSpace: 'Espacio del piso',
            zoneType: 'Tipo de zona',
            zoneGroups: 'Grupos de zonas',
            isActive: '¿Activo?',
            successfulSave: 'Guardado exitosamente...',
            notSuccessfulSave: 'Guardado no exitoso...',
            searchingDevice: 'Buscando dispositivo...',
            noResults: 'La búsqueda no tiene resultados...',
            atleastOneCharacter: 'Al menos un carácter...',
            profile: 'Perfil',
            logoutInProgress: 'Cierre de sesión en progreso...',
            name: 'Nombre',
            position: 'Posición',
            breakdown: 'Desglose',
            temperatureData: 'DATOS DE TEMPERATURA',
            populationData: 'DATOS DE POBLACIÓN',
            errorAlt: 'Una actualización de la página puede resolver el problema',
            permissionDenied: 'Permiso denegado',
            goBack: 'Ir a la página anterior',
            sessionExpired: 'La sesión ha expirado, ¡por favor intente iniciar sesión nuevamente!',
            loginAgain: 'Iniciaré sesión nuevamente',
            unauthorizedTenant: '¡El uso de esta aplicación no está permitido para su empresa!',
            saveError: 'Se produjeron errores durante el guardado',
            anonymus: 'Anónimo',
            deleteSuccessful: 'Eliminación exitosa',
            deleteNotSuccessful: 'Eliminación no exitosa',
            noViewImage: '¡No hay imagen disponible para esta vista!',
            deviceNamePlaceholder: 'ej. Nombre del dispositivo',
            yes: 'Confirmar',
            no: 'Negar',
            zoneNamePlaceholder: 'ej. Pasillo',
            egNumPlaceholder: 'ej. 100',
            search: 'Buscar',
            zoneMap: 'Mapa de zonas',
            temperatureMap: 'Mapa de temperatura',
            deviceMap: 'Mapa de dispositivos',
            zoneAdmin: 'Administrador de zonas',
            system: 'Sistema',
            scenarios: 'Escenarios',
            reports: 'Informes', 
            newScenario: 'Nuevo escenario',
            noScenarioAddedCurrently: 'No hay escenarios configurados aún.',
            selectOutput: 'Seleccionar salida',
            selectInputs: 'Seleccionar entradas',
            selectTime: 'Establecer hora de inicio',
            editScenario: 'Editar escenario',
            data: 'Datos',
            responders: 'Escenarios',
            syncSites: 'Sincronizar',
            outgoingIPAddress: 'Dirección IP de salida',
            chooseLang: 'Elegir idioma: ',
            hungarian: 'Húngaro',
            english: 'Inglés',
            downloadEkrReport: 'Descargar informe EKR',
        }
    },
    el: {
        message: {
            resolution: 'Ανάλυση',
            minutes: 'λεπτά',
            day: 'ημέρα',
            hour: 'ώρα',
            levels: 'Επίπεδα',
            infos: 'Πληροφορίες',
            currentPresenceGist: 'Παρούσα κατάσταση',
            numberOfWorkplaces: 'Αριθμός θέσεων εργασίας',
            sqm: 'τ.μ.',
            externalIdentifier: 'Εξωτερικός Αναγνωριστικός Κωδικός',
            regenerateApiKeyConfirmation: 'Είστε σίγουροι ότι θέλετε να αναγεννήσετε το κλειδί API;',
            regenerateApiKeyConfirmationCancel: 'Ακύρωση',
            regenerateApiKeyConfirmationOk: 'Ναι',
            generateApiKeyButtonTitle: 'Νέο κλειδί API',
            copy: 'Αντιγραφή',
            missingApiKey: 'Το κλειδί API δεν έχει δημιουργηθεί ακόμη',
            copiedToClipboard: 'Αντιγράφηκε στο πρόχειρο',
            generateApiKeyTitle: 'Κλειδί API',
            generateApiKeyExample: 'Παράδειγμα',
            noAssignedDevice: 'Δεν έχει ανατεθεί συσκευή',
            backToTheMap: 'Επιστροφή στον χάρτη',
            error: 'Σφάλμα',
            invalidMapView: 'Τα δεδομένα του φορτωμένου χάρτη είναι εσφαλμένα',
            loading: 'Φόρτωση...',
            loadedAreaHasNoCoordinates: 'Η φορτωμένη περιοχή δεν έχει γεωγραφικές συντεταγμένες.',
            device: 'Συσκευή',
            noSubArea: 'Δεν υπάρχουν υποπεριοχές συνδεδεμένες με αυτήν την περιοχή.',
            lidarView: 'Προβολή LIDAR',
            select: 'Επιλογή',
            close: 'Κλείσιμο',
            rain: 'Βροχή: ',
            displayName: 'Ονομασία',
            deviceType: 'Τύπος Συσκευής',
            choose: 'Επιλέξτε',
            adapterType: 'Τύπος προσαρμογέα',
            adapterConfig: 'Διαμόρφωση προσαρμογέα',
            save: 'Αποθήκευση',
            test: 'Δοκιμή',
            confirmRemove: 'Είστε βέβαιοι ότι θέλετε να αφαιρέσετε αυτή τη συσκευή;',
            confirmRemoveScenario: 'Είστε βέβαιοι ότι θέλετε να αφαιρέσετε αυτό το σενάριο;',
            noDevice: 'Δεν έχει προστεθεί συσκευή αυτή τη στιγμή',
            listOfDevices: 'Λίστα Συσκευών',
            addDevice: 'Προσθήκη Συσκευής',
            editDevice: 'Επεξεργασία Συσκευής',
            addNew: 'Προσθήκη νέας συσκευής',
            noFloorsFound: 'Δεν βρέθηκαν όροφοι για αυτό το κτίριο.',
            editZone: 'Επεξεργασία ζώνης',
            designation: 'Ονομασία',
            zoneName: 'Το όνομα της ζώνης',
            numberOfPossibleWorkplaces: 'Αριθμός πιθανών θέσεων εργασίας',
            floorSpace: 'Χώρος ορόφου',
            zoneType: 'Τύπος ζώνης',
            zoneGroups: 'Ομάδες ζωνών',
            isActive: 'Ενεργό;',
            successfulSave: 'Η αποθήκευση ήταν επιτυχής...',
            notSuccessfulSave: 'Η αποθήκευση δεν ήταν επιτυχής...',
            searchingDevice: 'Αναζήτηση συσκευής...',
            noResults: 'Η αναζήτηση δεν έχει αποτελέσματα...',
            atleastOneCharacter: 'Τουλάχιστον ένας χαρακτήρας...',
            profile: 'Προφίλ',
            logoutInProgress: 'Αποσύνδεση σε εξέλιξη...',
            name: 'Όνομα',
            position: 'Θέση',
            breakdown: 'Ανάλυση',
            temperatureData: 'ΔΕΔΟΜΕΝΑ ΘΕΡΜΟΚΡΑΣΙΑΣ',
            populationData: 'ΔΕΔΟΜΕΝΑ ΠΛΗΘΥΣΜΟΥ',
            errorAlt: 'Η ανανέωση της σελίδας μπορεί να λύσει το πρόβλημα',
            permissionDenied: 'Άρνηση πρόσβασης',
            goBack: 'Επιστροφή στην προηγούμενη σελίδα',
            sessionExpired: 'Η συνεδρία έληξε, παρακαλώ προσπαθήστε να συνδεθείτε ξανά!',
            loginAgain: 'Θα συνδεθώ ξανά',
            unauthorizedTenant: 'Η χρήση αυτής της εφαρμογής δεν επιτρέπεται για την εταιρεία σας!',
            saveError: 'Παρουσιάστηκαν σφάλματα κατά την αποθήκευση',
            anonymus: 'Ανώνυμος',
            deleteSuccessful: 'Η διαγραφή ήταν επιτυχής',
            deleteNotSuccessful: 'Η διαγραφή δεν ήταν επιτυχής',
            noViewImage: 'Δεν υπάρχει διαθέσιμη εικόνα για αυτή την προβολή!',
            deviceNamePlaceholder: 'π.χ. Όνομα Συσκευής',
            yes: 'Επιβεβαίωση',
            no: 'Άρνηση',
            zoneNamePlaceholder: 'π.χ. Διάδρομος',
            egNumPlaceholder: 'π.χ. 100',
            search: 'Αναζήτηση',
            zoneMap: 'Χάρτης Ζωνών',
            temperatureMap: 'Χάρτης Θερμοκρασίας',
            deviceMap: 'Χάρτης Συσκευών',
            zoneAdmin: 'Διαχειριστής Ζωνών',
            system: 'Σύστημα',
            scenarios: 'Σενάρια',
            reports: 'Αναφορές',
            newScenario: 'Νέο Σενάριο',
            noScenarioAddedCurrently: 'Δεν έχουν διαμορφωθεί ακόμη σενάρια.',
            selectOutput: 'Επιλογή Εξόδου',
            selectInputs: 'Επιλογή Εισόδων',
            selectTime: 'Ρύθμιση ώρας έναρξης',
            editScenario: 'Επεξεργασία Σενάριο',
            data: 'Δεδομένα',
            responders: 'Σενάρια',
            syncSites: 'Συγχρονισμός',
            outgoingIPAddress: 'Εξερχόμενη διεύθυνση IP',
            chooseLang: 'Επιλογή Γλώσσας: ',
            hungarian: 'Ουγγρικά',
            english: 'Αγγλικά',
            downloadEkrReport: 'Λήψη αναφοράς EKR',
        }
    },
    de: {
        message: {
            resolution: 'Auflösung',
            minutes: 'Minuten',
            day: 'Tag',
            hour: 'Stunde',
            levels: 'Ebenen',
            infos: 'Informationen',
            currentPresenceGist: 'Aktuelle Anwesenheit',
            numberOfWorkplaces: 'Anzahl der Arbeitsplätze',
            sqm: 'qm',
            externalIdentifier: 'Externe Kennung',
            regenerateApiKeyConfirmation: 'Sind Sie sicher, dass Sie den API-Schlüssel regenerieren möchten?',
            regenerateApiKeyConfirmationCancel: 'Abbrechen',
            regenerateApiKeyConfirmationOk: 'Ja',
            generateApiKeyButtonTitle: 'Neuer API-Schlüssel',
            copy: 'Kopieren',
            missingApiKey: 'API-Schlüssel ist noch nicht generiert',
            copiedToClipboard: 'In die Zwischenablage kopiert',
            generateApiKeyTitle: 'API-Schlüssel',
            generateApiKeyExample: 'Beispiel',
            noAssignedDevice: 'Kein zugewiesenes Gerät',
            backToTheMap: 'Zurück zur Karte',
            error: 'Fehler',
            invalidMapView: 'Die Daten der geladenen Karte sind inkorrekt',
            loading: 'Wird geladen...',
            loadedAreaHasNoCoordinates: 'Das geladene Gebiet hat keine geografischen Koordinaten.',
            device: 'Gerät',
            noSubArea: 'Es sind keine Unterbereiche an diesem Bereich angehängt.',
            lidarView: 'LIDAR-Ansicht',
            select: 'Auswählen',
            close: 'Schließen',
            rain: 'Regen: ',
            displayName: 'Anzeigename',
            deviceType: 'Gerätetyp',
            choose: 'Wählen',
            adapterType: 'Adaptertyp',
            adapterConfig: 'Adapterkonfiguration',
            save: 'Speichern',
            test: 'Testen',
            confirmRemove: 'Sind Sie sicher, dass Sie dieses Gerät entfernen möchten?',
            confirmRemoveScenario: 'Sind Sie sicher, dass Sie dieses Szenario entfernen möchten?',
            noDevice: 'Derzeit ist kein Gerät hinzugefügt',
            listOfDevices: 'Liste der Geräte',
            addDevice: 'Gerät hinzufügen',
            editDevice: 'Gerät bearbeiten',
            addNew: 'Neues Gerät hinzufügen',
            noFloorsFound: 'Keine Etagen für dieses Gebäude gefunden.',
            editZone: 'Zone bearbeiten',
            designation: 'Bezeichnung',
            zoneName: 'Zonenname',
            numberOfPossibleWorkplaces: 'Anzahl der möglichen Arbeitsplätze',
            floorSpace: 'Grundfläche',
            zoneType: 'Zonentyp',
            zoneGroups: 'Zonengruppen',
            isActive: 'Aktiv?',
            successfulSave: 'Erfolgreich gespeichert...',
            notSuccessfulSave: 'Speichern nicht erfolgreich...',
            searchingDevice: 'Gerät wird gesucht...',
            noResults: 'Die Suche hat keine Ergebnisse...',
            atleastOneCharacter: 'Mindestens ein Zeichen...',
            profile: 'Profil',
            logoutInProgress: 'Abmeldung läuft...',
            name: 'Name',
            position: 'Position',
            breakdown: 'Aufschlüsselung',
            temperatureData: 'TEMPERATURDATEN',
            populationData: 'BEVÖLKERUNGSDATEN',
            errorAlt: 'Ein Neuladen der Seite könnte das Problem lösen',
            permissionDenied: 'Zugriff verweigert',
            goBack: 'Zur vorherigen Seite gehen',
            sessionExpired: 'Die Sitzung ist abgelaufen, bitte versuchen Sie erneut, sich anzumelden!',
            loginAgain: 'Ich werde mich erneut anmelden',
            unauthorizedTenant: 'Die Nutzung dieser Anwendung ist für Ihr Unternehmen nicht gestattet!',
            saveError: 'Fehler beim Speichern aufgetreten',
            anonymus: 'Anonym',
            deleteSuccessful: 'Löschen erfolgreich',
            deleteNotSuccessful: 'Löschen war nicht erfolgreich',
            noViewImage: 'Es ist kein Bild für diese Ansicht verfügbar!',
            deviceNamePlaceholder: 'z.B. Gerätename',
            yes: 'Bestätigen',
            no: 'Ablehnen',
            zoneNamePlaceholder: 'z.B. Korridor',
            egNumPlaceholder: 'z.B. 100',
            search: 'Suchen',
            zoneMap: 'Zonenkarten',
            temperatureMap: 'Temperaturkarte',
            deviceMap: 'Gerätekarte',
            zoneAdmin: 'Zonen-Admin',
            system: 'System',
            scenarios: 'Szenarien',
            reports: 'Berichte',
            newScenario: 'Neues Szenario',
            noScenarioAddedCurrently: 'Derzeit sind keine Szenarien konfiguriert.',
            selectOutput: 'Ausgabe auswählen',
            selectInputs: 'Eingaben auswählen',
            selectTime: 'Startzeit festlegen',
            editScenario: 'Szenario bearbeiten',
            data: 'Daten',
            responders: 'Szenarien',
            syncSites: 'Synchronisieren',
            outgoingIPAddress: 'Ausgehende IP-Adresse',
            chooseLang: 'Sprache wählen: ',
            hungarian: 'Ungarisch',
            english: 'Englisch',
            downloadEkrReport: 'EKR-Bericht herunterladen',
        }
    }
}
export default messages;
