// LocalStorageService.js
const LocalStorageService = (function(){
  var _service;
  function _getService() {
      if(!_service) {
        _service = this;
        return _service
    }
    return _service
  }

  function _getSelectedAsset() {
    return localStorage.getItem('selected_asset');
  }

  function _setSelectedAsset(assetId) {
    localStorage.setItem('selected_asset', assetId);
  }

  function _clearSelectedAsset() {
    localStorage.removeItem('selected_asset');
  }

  return {
    getService : _getService,
    getSelectedAsset : _getSelectedAsset,
    setSelectedAsset : _setSelectedAsset,
    clearSelectedAsset : _clearSelectedAsset
  }
})();
export default LocalStorageService;
 