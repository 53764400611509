<template>
  <div class="p-0 h-full overflow-y-scroll">
    <div class="p-5 pb-2 mr-2 flex flex-col grow border-b-0 border-base-300">
      <div class="flex flex-row items-center gap-2 justify-center mb-3">
        <button class="text-white" @click="() => { backButtonFunction(); }">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
          </svg>
        </button>
        <h2 class="text-base font-sans font-semibold uppercase text-white flex flex-row items-center gap-2 grow">
          <span class="text-white mr-1">{{ $t("message.chooseScenarioType") }}</span>
        </h2>
        <a href="javascript:void(null)" class="hover:text-secondary" @click="hideSidebar">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </a>
      </div>

      <div class="grid grid-cols-1 gap-2 md:grid-cols-3 md:gap-2">
        <button type="button" class="btn btn-sm flex flex-col gap-2 items-center justify-center flex-nowrap p-2 py-10 bg-base-300 hover:bg-[#191e24] text-neutral-content rounded-md" v-for="(scenarioType, index) in state.scenarioTypes" :key="index" @click="() => { onTileClick(scenarioType); }">
          <div class="w-6 items-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z" />
            </svg>
          </div>
          <div class="grow flex flex-row items-start justify-start gap-2">
            <span class="text-slate-200 font-light text-center text-xs lowercase first-letter:uppercase">{{ scenarioType.name }}</span>
          </div>
        </button>
      </div>
      
    </div>
  </div>
</template>

<script setup>
  import store from '@/misc/vuex-store';
  import ScenarioTypesModel from '@/components/ScenarioTypes/ScenarioTypesModel';
  import { reactive, onMounted, computed } from 'vue';
  import toast from '@/misc/toast'

  function hideSidebar() {
    store.commit('hideSidebar');
  }

  const state = reactive({
    scenarioTypes: []
  })

  function onTileClick(selectedScenarioType) {
    store.commit('showSidebar', { type: 'addNewScenario', entity: selectedScenarioType, params: { 
      output: sidebarEntity.value && sidebarEntity.value.gistId ? {
        key: sidebarEntity.value.gistId,
        text: sidebarEntity.value.gistName
      } : null
    }});
  }

  function backButtonFunction() {
    var entity = store.getters.getSidebarEntity;
    if(entity.params && entity.params.back) {
      entity.params.back();
    }
  }

  const sidebarEntity = computed(() => {
    var entity = store.getters.getSidebarEntity;
    return entity.entity;
  });

  onMounted(() => {
    console.log(sidebarEntity.value)
    let targetGist = sidebarEntity.value && sidebarEntity.value.gistId ? sidebarEntity.value.gistId : null;
    ScenarioTypesModel.getScenarioTypes(targetGist, (response) => {
      state.scenarioTypes = response.data;
    }, () => {
      toast.error('Hiba történt a scenario típusok lekérdezése közben!')
    });
  });
</script>