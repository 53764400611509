import Axios from '@/misc/axios.js'

export default {
  getImageDimensions: (file) => {
    return new Promise (function (resolved) {
      var i = new Image()
      i.onload = function(){
        resolved({w: i.width, h: i.height})
      };
      i.src = file
    })
  },

  fetchImage: async (mapName) => {
    const response = await Axios.get(process.env.VUE_APP_FRONTENDV2_API_URL + '/image?image=' + mapName, {
      responseType: "arraybuffer"
    })
    .then(response => Buffer.from(response.data, 'binary').toString('base64'))
    .catch((e) => {
      console.log(e)
    })

    return response
  },

  mapClickConverter(geometry, mapDimensionsX, mapDimensionsY, e) {
    if(process.env.VUE_APP_CLICK_DEBUGGER_ENABLED != "true") {
      return ;
    }

    console.log("Original clicked position: [" + Math.round(e.latlng.lat) + "," + Math.round(e.latlng.lng) + "]")
    var xRatio = geometry.width / mapDimensionsX;
    var yRatio = geometry.length / mapDimensionsY;

    var x = e.latlng.lng * xRatio;
    var y = e.latlng.lat * yRatio;

    console.log("Transformed clicked position: " + Math.round(x) + ";" + Math.round(y))
  }
}