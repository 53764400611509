<template>
  <div class="px-2">
    <div class="flex flex-row items-center justify-between" v-if="props.scenarioType && props.scenarioType.configuration && props.scenarioType.configuration.inputsSpec && Object.keys(props.scenarioType.configuration.inputsSpec).length > 0">
      <GistSelectorComponent :title="$t('message.selectInputs')" subtitle="leírás" :adapterName="'demolight'" :gistType="'LIGHT'" :onValueChange="inputGistsChanged">
        <template #icon>
          <GistIcon :adapterName="'demolight'" :gistType="'LIGHT'"></GistIcon>
        </template>
      </GistSelectorComponent>
    </div>

    <div class="flex flex-row items-center justify-between" v-if="outputSpec">
      <GistSelectorComponent :defaultValue="outputDefaultValue" :maxSelectableItems="1" :title="$t('message.selectOutput')" subtitle="Max. 1 elem" :adapterName="outputSpec.adapterName" :gistType="outputSpec.gistType" :onValueChange="outputGistsChanged">
        <template #icon>
          <GistIcon :adapterName="'demolight'" :gistType="'LIGHT'"></GistIcon>
        </template>
      </GistSelectorComponent>
    </div>

    <div class="flex flex-row items-center justify-between">
      <TimedLightsOutScenario v-if="props.scenarioType.name == 'timed_lights_out'" :conditionsChanged="conditionsChanged" :defaultValue="props.scenario?.conditions" />
    </div>

    <div class="form-control w-full pt-5" @click="saveScenario">
      <button data-v-2c58454a="" type="button" class="btn btn-secondary">
        {{ $t("message.save") }}
      </button>
    </div>
  </div>
</template>

<script setup>
  import GistSelectorComponent from '@/components/ScenarioTypes/CommonComponents/GistSelectorComponent.vue';
  import GistIcon from '@/components/ScenarioTypes/CommonComponents/GistIcon.vue';
  import { reactive, computed } from 'vue';
  import TimedLightsOutScenario from '@/components/ScenarioTypes/TimedLightsOutScenario/ConditionsComponent.vue';
  import ScenarioTypesModel from './ScenarioTypesModel.js';
  import store from '@/misc/vuex-store.js'
  import toast from '@/misc/toast.js'

  const props = defineProps({
    scenarioType: Object,
    scenario: Object,
    defaultOutput: Object
  });

  const outputDefaultValue = computed(() => {
    if(props.scenario) {
      return [{
        key: props.scenario.output.id,
        text: props.scenario.output.displayName
      }];
    }
    else {
      if(props.defaultOutput) {
        return [props.defaultOutput];
      }
      else {
        return null;
      }
    }
  })

  const state = reactive({
    inputs: [],
    outputs: props.defaultOutput ? props.defaultOutput : null,
    conditions: {}
  })

  function outputGistsChanged(newValue) {
    if(newValue.length > 0) {
      state.outputs = newValue[0];
    }
  }

  function inputGistsChanged(newValue) {
    state.inputs = newValue;
  }

  function conditionsChanged(newValue) {
    state.conditions = newValue;
  }

  const outputSpec = computed(() => {
    if (props.scenarioType && props.scenarioType.configuration && props.scenarioType.configuration.outputSpec) {
      let keys = Object.keys(props.scenarioType.configuration.outputSpec);
      return props.scenarioType.configuration.outputSpec[keys[0]];
    }

    return null;
  });

  function saveScenario() {
    if(props.scenario) {
      ScenarioTypesModel.updateScenario(props.scenario.id, props.scenarioType.name, state.inputs, state.outputs, state.conditions, () => {
        store.commit('showSidebar', { type: 'scenarios' }); 
        // @TODO: Redirect to gist if it was updated from there
        toast.success("Sikeres mentés...");
      }, () => {
        toast.error("Sikertelen mentés, hiányzó adatok vannak az űrlapon!");
      });
    }
    else {
      ScenarioTypesModel.createScenario(props.scenarioType.name, state.inputs, state.outputs, state.conditions, () => {
        store.commit('showSidebar', { type: 'scenarios' });
        // @TODO: Redirect to gist if it was created from there
        toast.success("Sikeres mentés...");
      }, () => {
        toast.error("Sikertelen mentés, hiányzó adatok vannak az űrlapon!");
      });
    }
  }
</script>