<template>
  <div class="WeatherTooltip">
    <div class="dropdown dropdown-hover rounded-xl relative">
      <button ref="myBtn" class="flex flex-row justify-center items-center flex-nowrap overflow-visible">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 22" stroke-width="1.5" stroke="#ffffff" class="w-6 h-6 overflow-visible">
          <path stroke-linecap="round" class="fill-secondary" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
          <path stroke-linecap="round" class="fill-secondary" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
        </svg>
        <span v-if="weatherData" class="absolute -top-2 -right-5 bg-white rounded-xl text-xs z-[201] flex flex-row flex-nowrap bg-opacity-80 hover:bg-opacity-100 items-center align-middle shadow-lg" style="padding: 3px; padding-left: 5px; padding-right: 5px;"  :title="nameData">
          <i :class="'wi wi-' + weatherData.iconText + ' text-magenta flex-0'"></i>
          <span class="ml-1 flex-0 text-xs text-black font-bold" style="font-size: 10px;" :title="nameData">
            {{ presenceData }}
          </span>
          <span class="flex flex-row whitespace-nowrap flex-nowrap" style="font-size:10px;">
             / {{ numberOfWorkplacesData }}
          </span>
        </span>
      </button>
      <!-- <WeatherTooltipContent :weatherData="weatherData" :title="marker.name" v-if="weatherData"  /> -->
    </div>
  </div>
</template>

<script>

import 'weather-icons/css/weather-icons.css'
//import WeatherTooltipContent from '../../components/WeatherTooltipContent.vue';

export default {
  name: 'WeatherTooltip',
  props: {
    marker: {
      type: Object
    }
  },
  data() {
    return {
    }
  },
  watch: {
  },
  mounted() {
  },
  update() {
    
  },
  components: {
    // WeatherTooltipContent
  },
  computed: {
    weatherData() {
      const wData = this.$store.getters.getWeatherData(this.marker.id);
      return wData
    },
    presenceData() {
      const pData = this.$store.getters.getPresenceData(this.marker.id);
      return pData
    },
    numberOfWorkplacesData() {
      const pData = this.$store.getters.getNumberOfWorkplacesData(this.marker.id);
      return pData
    },
    nameData() {
      const pData = this.$store.getters.getNameData(this.marker.id);
      return pData
    },
  },
  methods: {
  }
}
</script>

<style scoped>
</style>
