<template>
  <div class="form-control">
    <label class="btn btn-sm btn-secondary btn-circle normal-case text-sm ml-2" for="themeSelectorModal">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4"><path stroke-linecap="round" stroke-linejoin="round" d="M9.53 16.122a3 3 0 00-5.78 1.128 2.25 2.25 0 01-2.4 2.245 4.5 4.5 0 008.4-2.245c0-.399-.078-.78-.22-1.128zm0 0a15.998 15.998 0 003.388-1.62m-5.043-.025a15.994 15.994 0 011.622-3.395m3.42 3.42a15.995 15.995 0 004.764-4.648l3.876-5.814a1.151 1.151 0 00-1.597-1.597L14.146 6.32a15.996 15.996 0 00-4.649 4.763m3.42 3.42a6.776 6.776 0 00-3.42-3.42" /></svg>
    </label>
    <input type="checkbox" id="themeSelectorModal" class="modal-toggle" />
    <div class="modal bg-base 200" id="themeSelectorModal">
      <div class="modal-box w-11/12 max-w-5xl">
        <div class="grid grid-cols-3 gap-5">
          <div v-for="(ctheme, key) in themes" :key="key" :data-theme="ctheme" class="card w-50 bg-base-100 shadow-xl">
            <div class="card-body">
              <h2 class="card-title">{{ ctheme }}</h2>
              <div class="card-actions justify-end">
                <label for="themeSelectorModal" @click="() => { setTheme(ctheme) }" class="btn btn-primary">{{ $t("message.select") }}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-action">
          <label for="themeSelectorModal" class="btn">{{ $t("message.close") }}</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ThemeSelector',
  data() {
    return {
      theme: 'dark',
      themes: ["light", "dark", "halloween", "forest", "black", "dracula", "business", "night", "coffee"],
    }
  },
  methods: {
    setTheme(theme) {
      this.$store.commit('setTheme', theme);
    }
  }
}

</script>