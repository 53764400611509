<template>
  <div id="MapLayout" class="h-screen box-border" :data-theme="theme">
    <div class="flex flex-col h-screen overflow-hidden">
      <div class="flex-0 p-2">
        <div class="navbar bg-base-0 rounded-box">
          <div class="flex-1">
            <slot name="leftButtons"></slot>
          </div>
          <div class="flex-1 justify-center">
            <a class="btn btn-ghost hover:bg-transparent normal-case text-sm">
              <img src="/img/logo-wt.png" class="h-14" />
            </a>
          </div>
          <div class="flex-1 gap-2 justify-end items-end">

            <!-- RESPONDERS -->
            <label class="tooltip tooltip-bottom !z-[999]" :data-tip="$t('message.scenarios')">
              <a @click="store.commit('showSidebar', { type: 'scenarios' });" class="btn btn-sm btn-secondary btn-circle normal-case text-sm ml-2">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                  <path stroke-linecap="round" stroke-linejoin="round" d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z" />
                </svg>
              </a>
            </label>
            <!-- RESPONDERS -->

            <!-- SYSTEM GISTS -->
            <label class="tooltip tooltip-bottom !z-[999]" :data-tip="$t('message.system')">
              <a v-if="User.hasPermission('ViewGists')" @click="showGistEditorSidebar" class="btn btn-sm btn-secondary btn-circle normal-case text-sm ml-2">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6 13.5V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 9.75V10.5" />
                </svg>
              </a>
            </label>
            <!-- SYSTEM GISTS -->

            <!-- SEARCH MOBILE -->
            <div v-if="props.withMenu" class="form-control">
              <label for="mobile-search-modal" class="tooltip tooltip-bottom !z-[999]" :data-tip="$t('message.search')">
                <a class="btn btn-sm btn-secondary btn-circle normal-case text-sm ml-2">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" /></svg>
                </a>
              </label>
              <input type="checkbox" id="mobile-search-modal" class="modal-toggle" v-model="state.isSearchModalOpen" />
              <label for="mobile-search-modal" class="modal cursor-pointer items-start !z-[11000] bg-opacity-75">
                <label class="modal-box relative overflow-visible p-1 bg-transparent pt-32 shadow-none" for="">
                  <multiselect
                      class="multiselect-search-input input input-sm flex flex-row-reverse p-0 pt-1 pb-1"
                      label="displayName"
                      track-by="id"
                      :loading="state.isLoading"
                      v-model="state.selectedAsset"
                      ref="multiselectObject"
                      :options="state.multiselectOptions"
                      :placeholder="$t('message.search')"
                      @search-change="asyncFind"
                      group-values="assets"
                      group-label="assetType"
                      :group-select="false"
                      selectLabel=""
                      deselectLabel=""
                      selectedLabel=""
                  >
                    <template #caret>
                      <div class="flex-0 h-full" style="padding: 8px 10px 0px 8px;">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#e53010" class="w-6 h-6">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 15.75l-2.489-2.489m0 0a3.375 3.375 0 10-4.773-4.773 3.375 3.375 0 004.774 4.774zM21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      </div>
                    </template>
                    <template #noResult>
                      {{ $t("message.noResults") }}
                    </template>
                    <template #tag>

                    </template>
                    <template #noOptions>
                      {{ $t("message.atleastOneCharacter") }}
                    </template>
                  </multiselect>
                </label>
              </label>
            </div>
            <!-- SEARCH MOBILE -->
             
            <!-- REPORTS -->
            <label v-if="User.hasPermission('ViewReports')" class="tooltip tooltip-bottom !z-[999]" :data-tip="$t('message.reports')">
              <a @click="router.push({ name: 'powerBiReportPage' })" class="btn btn-sm btn-secondary btn-circle normal-case text-sm ml-2">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                  <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                  <polyline points="14 2 14 8 20 8"></polyline>
                  <line x1="16" y1="13" x2="8" y2="13"></line>
                  <line x1="16" y1="17" x2="8" y2="17"></line>
                  <line x1="10" y1="9" x2="8" y2="9"></line>
                </svg>
              </a>
            </label>
            <!-- REPORTS -->


            <!-- LOGOUT -->
            <div class="dropdown dropdown-end z-[800]">
              <label tabindex="0" class="btn btn-sm btn-secondary btn-circle normal-case text-sm ml-2">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                </svg>
              </label>
              <div tabindex="0" class="dropdown-content card card-compact w-64 p-0 shadow bg-base-100 z-[800]">
                <div class="card-body">
                  <div class="flex items-center space-x-4 rounded-t-lg py-2 px-2">
                    <div class="w-full">
                      <label class="w-full text-center block text-xl font-bold text-slate-300 dark:text-slate-300">
                        {{ getUserName() }}
                      </label>
                      <p class="w-full text-center text-xs text-slate-400 dark:text-navy-300">
                        {{ getUserRoles().join(', ') }}
                      </p>
                      <div class="flex flex-row items-center justify-center mt-2 gap-2">
                        <a href="javascript:void(null)" @click="showUserProfileSidebar" class="grow m-auto btn btn-sm h-4 text-sm font-medium bg-secondary text-white">{{ $t("message.profile") }}</a>
                        <a href="javascript:void(null)" @click="logout" class="rounded-full m-auto px-2 btn btn-sm btn-ghost h-4 text-sm font-medium text-white">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
                          </svg>
                        </a>
                      </div>
                      <div class="flex flex-row items-center justify-center mt-2 gap-2">
                        <p>{{ $t("message.chooseLang") }}</p>
                      </div>
                      <div class="flex flex-row items-center justify-center mt-2 gap-2">
                        <a href="javascript:void(null)" @click="changeLanguageAndSave('en',$i18n)" class="grow m-auto btn btn-xs h-4 text-xs font-medium btn-outline text-white">EN<span v-if="$i18n.locale === 'en'" class="text-white font-bold">&nbsp;&#10004;</span></a>
                        <a href="javascript:void(null)" @click="changeLanguageAndSave('de',$i18n)" class="grow m-auto btn btn-xs h-4 text-xs font-medium btn-outline text-white">DE<span v-if="$i18n.locale === 'de'" class="text-white font-bold">&nbsp;&#10004;</span></a>
                        <a href="javascript:void(null)" @click="changeLanguageAndSave('hu',$i18n)" class="grow m-auto btn btn-xs h-4 text-xs font-medium btn-outline text-white">HU<span v-if="$i18n.locale === 'hu'" class="text-white font-bold">&nbsp;&#10004;</span></a>
                        <a href="javascript:void(null)" @click="changeLanguageAndSave('es',$i18n)" class="grow m-auto btn btn-xs h-4 text-xs font-medium btn-outline text-white">ES<span v-if="$i18n.locale === 'es'" class="text-white font-bold">&nbsp;&#10004;</span></a>
                        <a href="javascript:void(null)" @click="changeLanguageAndSave('el',$i18n)" class="grow m-auto btn btn-xs h-4 text-xs font-medium btn-outline text-white">EL<span v-if="$i18n.locale === 'el'" class="text-white font-bold">&nbsp;&#10004;</span></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- LOGOUT -->

            <ThemeSelector v-if="themeSelectorEnabled" />

          </div>
        </div>
      </div>
      <div class="flex-1 grow self-stretch p-0 pt-0 relative">
        <div class="flex h-full">
          <div class="flex-0 grow h-full max-h-full self-stretch p-2 pt-0">
            <div class="h-full rounded-box bg-base-0 overflow-hidden">
              <div class="drawer drawer-end h-full">
                <input v-model="state.drawerOpen" id="my-drawer" type="checkbox" class="drawer-toggle" />
                <div class="drawer-content relative !transform-none">
                  <div class="flex w-full h-full rounded-2xl bg-white" v-if="!siteDesignsLoaded">
                    <svg aria-hidden="true" class="flex-1 mt-5 mb-5 w-16 h-16 text-gray-200 animate-spin dark:text-gray-600 fill-secondary flex-0 content-center self-center" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                    </svg>
                  </div>
                  <slot name="mainContent" v-if="siteDesignsLoaded"></slot>
                </div>
                <div class="drawer-side h-full max-h-full rounded-xl">
                  <label for="my-drawer" class="drawer-overlay"></label>
                  <div class="h-full max-h-full menu bg-base-300 bg-opacity-90 w-[460px] flex flex-col overflow-y-hidden" id="set-my-height">
                    <SiteDesignSidebarContent v-if="sidebarEntity && sidebarEntity.type == 'siteDesign'" :params="sidebarEntity.params ? sidebarEntity.params : null" />
                    <ZoneSidebarContent v-if="sidebarEntity && sidebarEntity.type == 'zone'" :params="sidebarEntity.params ? sidebarEntity.params : null" />
                    <ZoneGroupSidebarContent v-if="sidebarEntity && sidebarEntity.type == 'zoneGroup'" :params="sidebarEntity.params ? sidebarEntity.params : null" />
                    <ZoneUpdaterSidebarContent v-if="sidebarEntity && sidebarEntity.type == 'zoneUpdate'" :entity="sidebarEntity.entity" :params="sidebarEntity.params ? sidebarEntity.params : null" />
                    <GistManagerSidebarContent v-if="sidebarEntity && sidebarEntity.type == 'gistManager'" :entity="sidebarEntity.entity" :params="sidebarEntity.params ? sidebarEntity.params : null" />
                    <UserProfileSidebarContent v-if="sidebarEntity && sidebarEntity.type == 'userProfile'" />
                    <ScenariosSidebarContent v-if="sidebarEntity && sidebarEntity.type == 'scenarios'" />
                    <ScenarioTypeSelectorSidebarContent v-if="sidebarEntity && sidebarEntity.type == 'scenarioTypeSelector'" />
                    <AddNewScenarioSidebarContent v-if="sidebarEntity && sidebarEntity.type == 'addNewScenario'" />
                    <EditScenarioSidebarContent v-if="sidebarEntity && sidebarEntity.type == 'editScenario'" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Multiselect from 'vue-multiselect'
import User from '../misc/User'
import Utils from '../misc/Utils'
import SiteDesignSidebarContent from './LayoutComponents/SiteDesignSidebarContent.vue'
import ZoneSidebarContent from './LayoutComponents/ZoneSidebarContent.vue'
import ZoneUpdaterSidebarContent from './LayoutComponents/ZoneUpdaterSidebarContent.vue'
import ZoneGroupSidebarContent from './LayoutComponents/ZoneGroupSidebarContent.vue'
import GistManagerSidebarContent from './LayoutComponents/GistManagerSidebarContent.vue'
import UserProfileSidebarContent from './LayoutComponents/UserProfileSidebarContent.vue'
import ScenariosSidebarContent from './LayoutComponents/ScenariosSidebarContent.vue'
import ScenarioTypeSelectorSidebarContent from './LayoutComponents/ScenarioTypeSelectorSidebarContent.vue'
import AddNewScenarioSidebarContent from './LayoutComponents/AddNewScenarioSidebarContent.vue'
import EditScenarioSidebarContent from './LayoutComponents/EditScenarioSidebarContent.vue'
import ThemeSelector from '../components/ThemeSelector.vue'
import Axios from '@/misc/axios'
import { useRoute } from 'vue-router'; const route = useRoute();
import "vue-multiselect/dist/vue-multiselect.ssr.css"
import router from '@/misc/router'
import { reactive, onMounted, watch, computed, ref } from 'vue'
import store from '@/misc/vuex-store'

const props = defineProps({
  withMenu: {
    type: Boolean,
    default: true
  }
})

const multiselectObject = ref(null)

const state = reactive({
  selectedAsset: null,
  multiselectOptions: [],
  isSearchModalOpen: false,
  isLoading: false,
  drawerOpen: false
})

onMounted(() => {
  Utils.storeAppConfig();
  Utils.storeSiteDesigns();

  var changeTableHeight = function() {
    const element = document.getElementById('set-my-height')
    element.style.height = (window.innerHeight - 80)+"px";
  }
  //window.removeEventListener('resize');
  window.addEventListener('resize', changeTableHeight);
  changeTableHeight()
})

const themeSelectorEnabled = computed(() => {
  return process.env.VUE_APP_THEME_SELECTOR_ENABLED == "true";
})

const theme = computed(() => {
  return store.getters.getTheme;
})

const sidebarEntity = computed(() => {
  return store.getters.getSidebarEntity;
})

const siteDesignsLoaded = computed(() => {
  return store.getters.getSiteDesignsLoaded;
})

watch(() => sidebarEntity.value, (val) => {
  if(val) {
    state.drawerOpen = true;
  }
  else {
    state.drawerOpen = false;
  }
})

watch(() => state.drawerOpen, (val) => {
  if(!val) {
    store.commit('hideSidebar');
  }
})

watch(() => state.selectedAsset, (val) => {
  if(val.id) {
    store.commit('setSelectedAsset', val.id);
    if(route.name != "devices" || route.params.siteDesignId != val.siteDesignId) {
      router.push({ name: 'devices', params: { siteDesignId: val.siteDesignId } })
    }

    state.isSearchModalOpen = false;
  }
})

watch(() => state.isSearchModalOpen, (val) => {
  if(val) {
    setTimeout(() => {
      multiselectObject.value.$el.focus()
    }, 100)
  }
})

function changeLanguageAndSave(locale, i18n) {
  i18n.locale = locale;
  store.commit("setLanguage",locale);
}

function logout() {
  User.logout();
}

function getUserName() {
  return User.getUserName();
}

function getUserRoles() {
  return User.getUserRoles();
}

function showUserProfileSidebar() {
  store.commit('showSidebar', { type: 'userProfile' });
}

function showGistEditorSidebar() {
  store.commit('showSidebar', { type: 'gistManager', entity: {displayName:"system", id:null}, params: {
      entityType: 'system',
      back: () => {
        store.commit('hideSidebar');
      }
    }});
}

function groupValues(data) {
  var tmp = {};
  for(var i in data) {
    if(!tmp[data[i].assetType]) {
      tmp[data[i].assetType] = [];
    }

    tmp[data[i].assetType].push(data[i]);
  }

  var result = [];
  for(var j in tmp) {
    result.push({
      assetType: j,
      assets: tmp[j]
    });
  }

  return result;
}

async function asyncFind(query) {
  if(query.length > 0) {
    state.isLoading = true
    const response = await Axios.get(process.env.VUE_APP_ADMIN_API_URL + '/assets/' + query, {
      onTokenRefreshed: () => {
        asyncFind(query);
      }
    });
    if(response && response.data) {
      state.multiselectOptions = groupValues(response.data);
    }
    state.isLoading = false
  }
}

</script>

<style scoped>
.multiselect-search-input >>> .multiselect__content-wrapper {
  z-index: 9999 !important;
  top: 55px;
  left: 0px;
  background: #2a303d8c;
  border: 1px solid #2a303d;
  color: white;
  border-radius: 6px !important;
}

.multiselect-search-input >>> .multiselect__spinner {
  height: 90%;
  background: #2a303c;
}

.multiselect-search-input >>> .multiselect__spinner::before, .multiselect-search-input >>> .multiselect__spinner::after {
  border-top-color: #e53010 !important;
}

.multiselect-search-input >>> .multiselect__option--group {
  background: #2a303d !important;
}

.multiselect-search-input >>> .multiselect__tags {
  background: transparent;
  border: none;
  line-height: 20px;
  padding-right: 0px;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.multiselect-search-input >>> .multiselect__tags input {
  background-color: transparent;
  color: white !important;
  font-size: 13px;
}

.multiselect-search-input >>> .multiselect__tags input::placeholder {
  color: #a6adba;
  font-size: 13px;
}

.multiselect-search-input >>> .multiselect__option--highlight {
  background-color: #e53010;
}

.multiselect-search-input >>> .multiselect__single {
  background-color: transparent;
  font-size: 13px;
  color: white;
}

/* .drawer-content {
  transform: none !important;
} */

.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active{
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-left-enter {
  transform: translateX(100%);
}

.slide-left-leave-to {
  transform: translateX(-100%);
}

.slide-right-enter {
  transform: translateX(-100%);
}

.slide-right-leave-to {
  transform: translateX(100%);
}
</style>
