<template>
  <div class="h-full overflow-y-scroll w-full">
    
    <div class="flex flex-row items-center gap-2">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" class="stroke-magenta w-10 h-10 mt-1 ml-3">
        <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
      </svg>
      <div class="p-5 pl-0 pb-2 mr-2 flex flex-col grow border-b-0 border-base-300">
        <div class="flex flex-row items-center justify-between">
          <h2 class="text-sm font-bold uppercase mb-1 text-secondary flex flex-row items-center gap-1">
            <span class="text-white">{{ sidebarEntity.name }}</span>
            <button v-if="User.hasPermission('ViewGists')" @click="showGistEditorSidebar" class="text-magenta font-light">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 13.5V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 9.75V10.5" />
              </svg>
            </button>
          </h2>
          <a href="javascript:void(null)" class="hover:text-secondary" @click="hideSidebar">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </a>
        </div>
        <h2 class="text-sm text-info-200 flex flex-row w-full gap-2">
          <div class=" max-w-xs text-sm breadcrumbs pt-0">
            <ul class="flex flex-row">
              <li class="flex flex-row text-xs" v-for="(bc, key) in breadcrumbs" :key="key">{{ bc }}</li>
            </ul>
          </div>
        </h2>
      </div>
    </div>

    <div v-if="sidebarEntity.type != 'floor'" class="w-full">
      <div class="tabs w-full flex flex-row flex-nowrap pl-5">
        <a @click="() => { state.currentTab = 'levels' }" class="tab tab-lifted text-secondary flex flex-row gap-1" :class="[{'tab-active': state.currentTab == 'levels'}]">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21" />
          </svg>
          <span class="text-xs text-white">{{ $t("message.levels") }}</span>
        </a> 
        <a @click="() => { state.currentTab = 'infos' }" class="tab tab-lifted text-secondary-focus flex flex-row gap-1" :class="[{'tab-active': state.currentTab == 'infos'}]">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
            <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
          </svg>
          <span class="text-xs text-white">{{ $t("message.infos") }}</span>
        </a> 
      </div>
    </div>

    <div v-if="state.currentTab == 'levels' && sidebarEntity.type != 'floor'" class="p-5">
      <div v-if="(currentSiteDesignChildren.length <= 0)" class="alert alert-warning shadow-lg">
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" class="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>
          <span>{{ $t("message.noFloorsFound") }}</span>
        </div>
      </div>
      <div v-else class="grid grid-cols-1 gap-2 md:grid-cols-3 md:gap-2">
        <button type="button" class="btn btn-sm flex flex-col gap-2 items-center justify-center flex-nowrap p-2 py-10 bg-base-300 hover:bg-[#191e24] text-neutral-content rounded-md" v-for="(siteDesign, index) in currentSiteDesignChildren" :key="index" @click="() => { onTileClick(siteDesign.id); }">
          <div v-if="siteDesign.type == 'floor'" class="w-6 items-center">
            <svg v-if="!siteDesign.map" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-dark">
              <path stroke-linecap="round" stroke-linejoin="round" d="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z" />
            </svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-secondary">
              <path stroke-linecap="round" stroke-linejoin="round" d="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z" />
            </svg>
          </div>
          <div v-if="siteDesign.type == 'building'" class="text-secondary">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
              <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
            </svg>

          </div>
          <div class="grow flex flex-row items-start justify-start gap-2">
            <span class="text-slate-200 font-light text-center text-xs lowercase first-letter:uppercase">{{ siteDesign.name }}</span>
            <!-- <div v-if="siteDesign.map == null" class="badge badge-warning" title="Hiányzó alaprajz!">
              <svg xmlns="http://www.w3.org/2000/svg" class="stroke-current flex-shrink-0 h-4 w-4" fill="none" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>
            </div> -->
          </div>

        </button>
      </div>
    </div>
    <div v-if="state.currentTab == 'infos' || sidebarEntity.type == 'floor'" class="p-5">
      <GistsBaseComponent :entity="sidebarEntity" type="site-design" />
    </div>
  </div>
</template>

<script setup>
  import User from '@/misc/User';
  import GistsBaseComponent from '@/components/Gists/GistsBaseComponent.vue';
  import Utils from '@/misc/Utils';
  import store from '@/misc/vuex-store';
  import { reactive, computed } from 'vue';
  import router from '@/misc/router';

  const state = reactive({
    currentTab: 'levels'
  });

  const hideSidebar = () => {
    store.commit('hideSidebar');
  }

  const onTileClick = (siteDesignId) => {
    var siteDesign = Utils.getSiteDesignById(siteDesignId);

    if(siteDesign.type == 'building') {
      store.commit('showSidebar', { type: 'siteDesign', entity: siteDesign });
      return;
    }
    
    var defaultRoute = "map"
    if(!siteDesign.map) {
      defaultRoute = "devices"
    }

    store.commit('setSelectedSiteDesign', siteDesign);
    router.push({ name: defaultRoute, params: { siteDesignId: siteDesign.id } })
  }

  const showGistEditorSidebar = () => {
    store.commit('showSidebar', { type: 'gistManager', entity: sidebarEntity.value, params: {
      entityType: 'site-design',
      back: () => {
        store.commit('showSidebar', { type: 'siteDesign', entity: sidebarEntity.value });
      }
    }});
  }

  const sidebarEntity = computed(() => {
    var entity = store.getters.getSidebarEntity;
    return entity.entity;
  });

  const siteDesigns = computed(() => {
    return store.getters.getSiteDesigns;
  });

  const currentSiteDesignChildren = computed(() => {
    if(!sidebarEntity.value) {
      return [];
    }

    var children = [];
    siteDesigns.value.forEach((siteDesign) => {
      if(siteDesign.parentId == sidebarEntity.value.id) {
        children.push(siteDesign);
      }
    });

    return children.sort(function(a, b) {
      var x = a['name']; var y = b['name'];
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
  });

  const breadcrumbs = computed(() => {
    var breadcrumbs = [];
    var items = sidebarEntity.value.siteNameHierarchy.split("/");
    for(let i in items) {
      if(items[i].toLowerCase() == "global") {
        continue;
      }
      breadcrumbs.push(items[i]);
    }
    return breadcrumbs;
  });


</script>