<template>
  <div class="tooltip tooltip-right tooltip-secondary" :data-tip="$t('message.backToTheMap')">
    <button class="btn btn-secondary btn-sm btn-circle" @click="backToSiteDesignSelector">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
      </svg>
    </button>
  </div>
</template>

<script setup>
  import router from '@/misc/router';
  import store from '@/misc/vuex-store';

  function backToSiteDesignSelector() {
    store.commit('setSelectedSiteDesign', null);
    router.push({ name: 'siteDesignSelector' })
  }

</script>

<style scoped>
</style>
