<template>
  <div class="w-full">
    <div class="flex flex-row w-full items-center justify-center">
      <button :disabled="stepBackDisabled" class="flex-0 self-center btn btn-secondary btn-sm ml-3 z-50" @click="stepBack">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
          <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
        </svg>
      </button>
      <div class="flex-1 flex flex-col items-center justify-center">
        <h1 class="flex-1 self-center heading-3 text-white font-sans text-xs" v-if="minuteStep <= 60">{{ $t("message.resolution") }}: {{ minuteStep }} {{ $t("message.minutes") }}</h1>
        <h1 class="flex-1 self-center heading-3 text-white font-sans text-xs" v-else>{{ $t("message.resolution") }}: 1 {{ $t("message.day") }}</h1>
      </div>
      <button :disabled="stepForwardDisabled" class="flex-0 self-center btn btn-secondary btn-sm mr-3 z-50" @click="stepForward">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
          <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
        </svg>
      </button>
    </div>
    <apexchart class="flex-1 w-full" type="bar" :options="chartOptions" :series="barDataOptimized"></apexchart>
  </div>
</template>

<script>
  import Utils from '../../../misc/Utils'
  import moment from 'moment';

  export default {
    name: 'TendencyBar',
    props: {
      minuteStep: {
        type: Number,
        default: 60
      },
      barData: {
        type: Array,
        default: () => {}
      }
    },
    mounted() {
      this.setCenterTime();
      this.optimizeBarData();
    },
    data() {
      return {
        centerTime: null,
        barDataOptimized: [],
        annotationPoints: []
      }
    },
    watch: {
      minuteStep() {
        this.setCenterTime();
        this.optimizeBarData();
      },
      barData() {
        this.optimizeBarData();
      }
    },
    methods: {
      setCenterTime() {
        switch(this.minuteStep) {
          case 1440:
            this.centerTime = moment(Utils.roundToNearest1440(new Date()))
            break;
          case 60:
            this.centerTime = moment(Utils.roundToNearest60(new Date()))
            break;
          case 15:
            this.centerTime = moment(Utils.roundToNearest15(new Date()))
            break;
        }
      },
      getDataAndGoalAndAnnotationForTime(momentTime) {
        let result = {
          data: 0,
          goal: null
        };

        if(this.minuteStep != 1440) {
          let parts = momentTime.split(':');
          for(let i in this.barData) {
            if(this.barData[i].hour == parts[0] && this.barData[i].minute == parts[1]) {
              result.data = this.barData[i].value;
              if(this.barData[i].goal) {
                result.goal = this.barData[i].goal;
              }
              if(this.barData[i].annotation) {
                result.annotation = this.barData[i].annotation;
              }
              break;
            }
          }
        }

        if(this.minuteStep == 1440) {
          for(let i in this.barData) {
            if(this.barData[i].day == momentTime) {
              result.data = this.barData[i].value;
              if(this.barData[i].goal) {
                result.goal = this.barData[i].goal;
              }
              if(this.barData[i].annotation) {
                result.annotation = this.barData[i].annotation;
              }
              break;
            }
          }
        }
        return result;
      },
      formatTime(time) {
        let parts = time.split(':');
        return moment().hour(parts[0]).minute(parts[1]).format('HH:mm');
      },
      optimizeBarData() {
        let keys = ['past', 'current', 'future'];
        var result = [];

        for(var k in keys) {
          for(var i in this.currentTimeKeys[keys[k]]) {
            let xLabel;
            if(this.minuteStep != 1440) {
              xLabel = this.formatTime(this.currentTimeKeys[keys[k]][i]);
            }
            else {
              xLabel = this.currentTimeKeys[keys[k]][i];
            }

            const dataAndGoal = this.getDataAndGoalAndAnnotationForTime(this.currentTimeKeys[keys[k]][i]);
            const cItem = {
              x: xLabel,
              y: dataAndGoal.data
            };

            /* @TODO: Test goals */
            if(dataAndGoal.goal) {
              cItem.goal = [{
                name: '',
                value: dataAndGoal.goal,
                strokeHeight: 2,
                strokeColor: '#e10174'
              }];
            }

            /* @TODO: Test annotations */
            if(dataAndGoal.annotation) {
              this.annotationPoints.push({
                x: xLabel,
                seriesIndex: 0,
                label: {
                  borderColor: '#775DD0',
                  offsetY: 0,
                  style: {
                    color: '#fff',
                    background: '#775DD0',
                  },
                  text: dataAndGoal.annotation,
                }
              })
            }

            result.push(cItem);
          }
        }

        this.barDataOptimized = [{
          data: result
        }]
      },
      stepBack(e) {
        e.stopPropagation();
        e.preventDefault();
        this.centerTime = moment(this.centerTime).subtract('minutes', this.minuteStep)
        this.optimizeBarData();
      },
      stepForward(e) {
        e.stopPropagation();
        e.preventDefault();
        this.centerTime = moment(this.centerTime).add('minutes', this.minuteStep)
        this.optimizeBarData();
      },
    },
    computed: {
      labels() {
        var result = [];
        for(var i in this.currentTimeKeys.past) {
          result.push(this.currentTimeKeys.past[i].timeReadable);
        }
        for(var j in this.currentTimeKeys.current) {
          result.push(this.currentTimeKeys.current[j].timeReadable);
        }
        for(var k in this.currentTimeKeys.future) {
          result.push(this.currentTimeKeys.future[k].timeReadable);
        }

        return result;
      },
      stepBackDisabled() {
        if(!this.centerTime) return false;

        if(this.minuteStep == 1440) {
          if(moment(this.centerTime).format("YYYY-MM") != moment(this.centerTime).subtract('days', 1).format("YYYY-MM")) {
            return true;
          }
          else {
            return false;
          }
        }
        else {
          if(this.centerTime.format("YYYY-MM-DD") != moment(this.centerTime).subtract('minutes', this.minuteStep).format("YYYY-MM-DD")) {
            return true;
          }
          else {
            return false;
          }
        }
      },
      stepForwardDisabled() {
        if(!this.centerTime) return false;

        if(this.minuteStep == 1440) {
          if(moment(this.centerTime).format("YYYY-MM") != moment(this.centerTime).add('days', 1).format("YYYY-MM")) {
            return true;
          }
          else {
            return false;
          }
        }
        else {
          if(this.centerTime.format("YYYY-MM-DD") != moment(this.centerTime).add('minutes', this.minuteStep).format("YYYY-MM-DD")) {
            return true;
          }
          else {
            return false;
          }
        }
      },
      currentTimeKeys() {
        var past = [];
        var current = [];
        var future = [];

        if(this.minuteStep != 1440) {
          if(moment(this.centerTime).format("YYYY-MM-DD") == moment(this.centerTime).subtract('minutes', 3*this.minuteStep).format("YYYY-MM-DD")) { // same day?
            past.push(moment(this.centerTime).subtract('minutes', 3*this.minuteStep).format("H:m"));
          }

          if(moment(this.centerTime).format("YYYY-MM-DD") == moment(this.centerTime).subtract('minutes', 2*this.minuteStep).format("YYYY-MM-DD")) { // same day?
            past.push(moment(this.centerTime).subtract('minutes', 2*this.minuteStep).format("H:m"));
          }

          if(moment(this.centerTime).format("YYYY-MM-DD") == moment(this.centerTime).subtract('minutes', 1*this.minuteStep).format("YYYY-MM-DD")) { // same day?
            past.push(moment(this.centerTime).subtract('minutes', 1*this.minuteStep).format("H:m"));
          }
          
          current.push(moment(this.centerTime).format("H:m"))

          if(moment(this.centerTime).format("YYYY-MM-DD") == moment(this.centerTime).add('minutes', 1*this.minuteStep).format("YYYY-MM-DD")) { // same day?
            future.push(moment(this.centerTime).add('minutes', 1*this.minuteStep).format("H:m"));
          }

          if(moment(this.centerTime).format("YYYY-MM-DD") == moment(this.centerTime).add('minutes', 2*this.minuteStep).format("YYYY-MM-DD")) { // same day?
            future.push(moment(this.centerTime).add('minutes', 2*this.minuteStep).format("H:m"));
          }

          if(moment(this.centerTime).format("YYYY-MM-DD") == moment(this.centerTime).add('minutes', 3*this.minuteStep).format("YYYY-MM-DD")) { // same day?
            future.push(moment(this.centerTime).add('minutes', 3*this.minuteStep).format("H:m"));
          }
        }
        else {
          for(let i in [3,2,1]) {
            if(moment(this.centerTime).subtract(3-i, "days").format("YYYY-MM") == moment(this.centerTime).format("YYYY-MM")) { // same month?
              past.push(moment(this.centerTime).subtract(3-i, "days").format("D"));
            }
          }

          current.push(this.centerTime.format("D"))

          for(let i in [1,2,3]) {
            if(moment(this.centerTime).add(parseInt(i)+1, "days").format("YYYY-MM") == moment(this.centerTime).format("YYYY-MM")) { // same month?
              future.push(moment(this.centerTime).add(parseInt(i)+1, "days").format("D"));
            }
          }
        }

        return {
          past: past,
          current: current,
          future: future
        }
      },
      barColors() {
        const pastColorCode = process.env.VUE_APP_PAST_BAR_COLOR;
        const currentColorCode = process.env.VUE_APP_CURRENT_BAR_COLOR;
        const futureColorCode = process.env.VUE_APP_FUTURE_BAR_COLOR;

        var result = []; var i = 0;
        for(i=0;i<this.currentTimeKeys.past.length;++i) { result.push(pastColorCode); }
        for(i=0;i<this.currentTimeKeys.current.length;++i) { result.push(currentColorCode); }
        for(i=0;i<this.currentTimeKeys.future.length;++i) { result.push(futureColorCode); }

        return result;
      },
      chartOptions() {
        var res = {
          dataLabels: { enabled: true},
          grid: { show: true, borderColor: '#313845', strokeDashArray: 2, xaxis: { lines: { show: false } }, yaxis: { lines: { show: true } } },
          legend: { show: false },
          stroke: { show: false },
          yaxis: { show: false },
          tooltip: { enabled: false },
          labels: [],
          colors: this.barColors,

          annotations: {
            points: this.annotationPoints
          },

          plotOptions: {
            bar: {
              distributed: true
            },
          },

          chart: {
            toolbar: { show: false },
            type: 'bar',
            height: 241,
            events: {
              // click: function(chart, w, e) {
              //   console.log(chart, w, e)
              // }
            }
          },

          xaxis: {
            show: true,
            tickPlacement: 'on',
            tickAmount: "dataPoints",
            convertedCatToNumeric: true,
            labels: {
              offsetY: -4,
              style: {
                colors: ['#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff'],
                fontSize: '12px',
                fontWeight: 'light',
                fontFamily: 'Montserrat'
              },

            },
            axisBorder: {
              show: false
            }
          }
        }

        return res;
      }
    }
  }
</script>