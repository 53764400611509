<template>
  <div id="EditorPolygonContent" class="text-center z-40 rounded-xl">
    <button for="editNameModal" class="rounded-full text-xs z-[650] bg-white cursor-pointer px-2 drop-shadow-md shadow-md" @dblclick="killDblClick" :title="zoneName">
      <label class="cursor-pointer max-w-[70px] text-xs overflow-ellipsis overflow-hidden font-light text-slate-800 font-sans flex flex-row gap-1">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-2 h-2 m-auto text-slate-600" :title="zoneName">
          <path :title="zoneName" stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
        </svg>
        <span>{{ zoneName }}</span>
      </label>
    </button>
  </div>
</template>

<script>

export default {
  name: 'EditorPolygonContent',
  props: {
    zoneId: {
      type: [Number],
      default: null
    },
    zoneName: {
      type: [String],
      default: 'N/A'
    }
  },
  components: {
  },
  data() {
    return {
    }
  },
  computed: {
  },
  mounted() {
  },
  watch: {
  },
  methods: {
    killDblClick(e) {
      e.stopPropagation();
      e.preventDefault();
      return;
    },
  }
}
</script>

<style scoped>
</style>
