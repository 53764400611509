<template>
  <div class="flex flex-col w-[412px]" v-if="gistTypes && gistTypes.length > 0">
    <div v-for="(gistType, index) in gistTypes" :key="index">

      <!-- <VueHorizontal snap="start" class="vueHorizontal">
        <section class="w-full">
          <DefaultDisplayer :entity="entity" :entityType="type" :gistType="gistType.gistType" class="mb-3">
            <template #icon>
              <svg v-if="gistType.gistType == 'PRESENCE'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" class="w-5 h-5 mr-1">
                <path stroke-linecap="round" stroke-linejoin="round" d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z" />
              </svg>
              <svg v-if="gistType.gistType == 'WEATHER' || gistType.gistType == 'WEATHER_FORECAST'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" class="w-5 h-5 mr-1">
                <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 15a4.5 4.5 0 004.5 4.5H18a3.75 3.75 0 001.332-7.257 3 3 0 00-3.758-3.848 5.25 5.25 0 00-10.233 2.33A4.502 4.502 0 002.25 15z" />
              </svg>
            </template>
          </DefaultDisplayer>
        </section>

        <section class="w-full">
          <DefaultDisplayer :entity="entity" :entityType="type" :gistType="gistType.gistType" class="mb-3">
            <template #icon>
              <svg v-if="gistType.gistType == 'PRESENCE'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" class="w-5 h-5 mr-1">
                <path stroke-linecap="round" stroke-linejoin="round" d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z" />
              </svg>
              <svg v-if="gistType.gistType == 'WEATHER' || gistType.gistType == 'WEATHER_FORECAST'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" class="w-5 h-5 mr-1">
                <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 15a4.5 4.5 0 004.5 4.5H18a3.75 3.75 0 001.332-7.257 3 3 0 00-3.758-3.848 5.25 5.25 0 00-10.233 2.33A4.502 4.502 0 002.25 15z" />
              </svg>
            </template>
          </DefaultDisplayer>
        </section>
      </VueHorizontal> -->

      <DefaultDisplayer :entity="entity" :entityType="type" :gist="gistType" class="mb-3">
        <template #icon>
          <svg v-if="gistType.gistType == 'PRESENCE'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" class="w-5 h-5 mr-1">
            <path stroke-linecap="round" stroke-linejoin="round" d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z" />
          </svg>
          <svg v-if="gistType.gistType == 'WEATHER' || gistType.gistType == 'WEATHER_FORECAST'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" class="w-5 h-5 mr-1">
            <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 15a4.5 4.5 0 004.5 4.5H18a3.75 3.75 0 001.332-7.257 3 3 0 00-3.758-3.848 5.25 5.25 0 00-10.233 2.33A4.502 4.502 0 002.25 15z" />
          </svg>
        </template>
      </DefaultDisplayer>
    </div>
  </div>
  <div class="text-xs alert" v-else>
    {{ $t("message.noAssignedDevice") }}
  </div>
</template>

<script>
  import Utils from '../../misc/Utils';
  import DefaultDisplayer from './displayers/DefaultDisplayer.vue';
  //import VueHorizontal from 'vue-horizontal';

  export default {
    name: 'GistsBaseComponent',
    props: {
      entity: {
        type: Object,
        required: true
      },
      type: {
        type: String,
        required: true
      }
    },
    components: {
      DefaultDisplayer,
      //VueHorizontal
    },
    mounted() {
      this.getGistTypes()
    },
    data() {
      return {
        gistTypes: null
      }
    },
    methods: {
      getGistTypes() {
        if(this.gistTypes) return;

        Utils.getGistsByTypeAndId(this.type, this.entity.id, (response) => {
          this.gistTypes = response.data;
        });
      }
    }
  }
</script>
<!-- Responsive Breakpoints -->
<style scoped>
  .vueHorizontal>>>.v-hl-svg {
    background: #1f242d;
    color: white;
    box-shadow: 1px 1px 3px rgb(0 0 0 / 71%), 0 1px 2px rgba(0,0,0,.24);
  }
</style>