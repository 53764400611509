<template>
    <label class="tooltip tooltip-bottom !z-[999]" :data-tip="$t('message.downloadEkrReport')">
        <p class="mb-2 text-white text-lg">{{ $t("message.downloadEkrReport") }}</p>
        <a @click="downloadEkrReport" class="btn btn-xl btn-secondary normal-case text-sm ml-2">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" class="w-12 h-12">
                <path d="M12 16l4-4h-3V4h-2v8H8l4 4zm-6 2v2h12v-2H6z"/>
            </svg>
        </a>
    </label>
</template>

<script setup> 
    import Axios from "../../misc/axios";
    function downloadEkrReport() {
        Axios.get(process.env.VUE_APP_FRONTENDV2_API_URL + '/reports/ekr-report', { responseType: 'blob' })
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            const disposition = response.headers['content-disposition'];
            let filename = 'EKR_Report.csv';

            if (disposition && disposition.includes('filename=')) {
                filename = disposition.split('filename=')[1].split(';')[0].replace(/"/g, '');
            }
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
        });
    }
</script>