<template>
  <div class="p-0 h-full overflow-y-scroll">
    <div class="p-5 pb-2 mr-2 flex flex-col grow">
      <div class="flex flex-row items_center justify-between">
        <h2 class="text-base font-sans font-semibold uppercase text-white flex flex-row items-center justify-center gap-2">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
            <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 7.125C2.25 6.504 2.754 6 3.375 6h6c.621 0 1.125.504 1.125 1.125v3.75c0 .621-.504 1.125-1.125 1.125h-6a1.125 1.125 0 01-1.125-1.125v-3.75zM14.25 8.625c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v8.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-8.25zM3.75 16.125c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v2.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-2.25z" />
          </svg>
          <span class="text-white mr-1">{{ sidebarEntity.displayName }}</span>
          <div class="flex flex-row">
            <!-- <div class="tooltip tooltip-right" data-tip="edit gists"> -->
              <button v-if="User.hasPermission('ViewGists')" @click="showGistEditorSidebar" class="text-magenta font-light">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6 13.5V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 9.75V10.5" />
                </svg>
              </button>
            <!-- </div> -->
          </div>
        </h2>
        <a href="javascript:void(null)" class="hover:text-secondary" @click="hideSidebar">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </a>
      </div>
      <div class="flex flex-row items-start justify-start gap-2 whitespace-nowrap flex-nowrap overflow-x-scroll text-ellipsis max-w-full hide-scrollbar mb-1">
        <button @click="() => { showZoneGroupSidebar(zoneGroup) }" v-for="(zoneGroup, key) in zoneGroups" :key="key" :style="'background-color:' + zoneGroup.color + ';'" :class="'btn border-none !h-5 rounded-md min-h-0 !py-0 px-2 text-white !leading-0 flex flex-row gap-1 opacity-90'">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3">
            <path stroke-linecap="round" stroke-linejoin="round" d="M9.568 3H5.25A2.25 2.25 0 003 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 005.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 009.568 3z" />
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 6h.008v.008H6V6z" />
          </svg>
          <span style="font-size: 10px !important;" class="font-normal font-sans opacity-80">{{ zoneGroup.displayName }}</span>
        </button>
      </div>
      <div class="flex flex-row justify-evenly mt-1 gap-2">
        <div style="background-image:url('/icons/presence.svg')" class="flex flex-col w-1/4 bg-base-300 rounded-md p-3 overflow-hidden text-ellipsis whitespace-normal bg-no-repeat bg-50% bg-center">
          <h4 class="text-xs h-8 uppercase font-light" style="font-size:9px">{{ $t("message.currentPresenceGist") }}</h4>
          <span class="text-md text-white">{{ sidebarEntity.currentPresenceGist }}</span>
        </div>
        <div style="background-image:url('/icons/workplace.svg')" class="flex flex-col w-1/4 bg-base-300 rounded-md p-3 overflow-hidden text-ellipsis whitespace-normal bg-no-repeat  bg-50% bg-center">
          <h4 class="text-xs h-8 uppercase opacity-70 font-light" style="font-size:9px">{{ $t("message.numberOfWorkplaces") }}</h4>
          <span class="text-md text-white">{{ sidebarEntity.numberOfWorkplaces }}</span>
        </div>
        <div style="background-image:url('/icons/building.svg')" class="flex flex-col w-1/4 bg-base-300 rounded-md p-3 overflow-hidden text-ellipsis whitespace-normal bg-no-repeat bg-50% bg-center">
          <h4 class="text-xs h-8 uppercase opacity-70 font-light" style="font-size:9px">{{ $t("message.sqm") }}</h4>
          <span class="text-md text-white">{{ sidebarEntity.sqm }}</span>
        </div>
        <div style="background-image:url('/icons/id.svg')" class="flex flex-col w-1/4 bg-base-300 rounded-md p-3 overflow-hidden text-ellipsis whitespace-normal bg-no-repeat bg-50% bg-center">
          <h4 class="text-xs h-8 uppercase opacity-70 font-light" style="font-size:9px">{{ $t("message.externalIdentifier") }}</h4>
          <span class="text-md text-white">{{ sidebarEntity.externalIdentifier }}</span>
        </div>
      </div>
    </div>

    <div class="px-5">
      <GistsBaseComponent :entity="sidebarEntity" type="zone" />
    </div>
  </div>
</template>

<script setup>
  import User from '@/misc/User';
  import GistsBaseComponent from '@/components/Gists/GistsBaseComponent.vue';
  import store from '@/misc/vuex-store';
  import { computed } from 'vue';

  function hideSidebar() {
    store.commit('hideSidebar');
  }

  function showZoneGroupSidebar(zoneGroup) {
    const zoneSidebarTmp = JSON.parse(JSON.stringify(sidebarEntity.value))
    const zoneGroupsTmp = JSON.parse(JSON.stringify(zoneGroups.value))
    store.commit('showSidebar', {
      type: 'zoneGroup', 
      entity: zoneGroup, 
      params: {
        back: () => {
          store.commit('showSidebar', { 
            type: 'zone', 
            entity: zoneSidebarTmp,
            params: {
              zoneGroups: zoneGroupsTmp
            }
          });
        }
      }
    });
  }

  function showGistEditorSidebar() {
    const zoneSidebarTmp = JSON.parse(JSON.stringify(sidebarEntity.value))
    const zoneGroupsTmp = JSON.parse(JSON.stringify(zoneGroups.value))
    store.commit('showSidebar', { type: 'gistManager', entity: zoneSidebarTmp, params: {
      entityType: 'zone',
      back: () => {
        store.commit('showSidebar', { 
          type: 'zone', 
          entity: zoneSidebarTmp,
          params: {
            zoneGroups: zoneGroupsTmp
          }
        });
      }
    }});
  }

  const sidebarEntity = computed(() => {
    var entity = store.getters.getSidebarEntity;
    return entity.entity;
  });

  const zoneGroups = computed(() => {
    var entity = store.getters.getSidebarEntity;
    return entity.params.zoneGroups;
  });

</script>