import UserRoles from "./UserRoles";

export default {
  [UserRoles.NRGMAP_USER]: [
    'MainMap',
    'MapView',
    'Heatmap',
    // 'Devices', // Uncomment this line to enable the Devices page for NRGMAP_USER
    'TemperatureMapView',
    'ViewGists'
  ],
  [UserRoles.NRGMAP_ADMIN]: [
    'MainMap',
    'MapView',
    'Heatmap',
    'Devices',
    'ZoneAdmin',
    'TemperatureMapView',
    'ViewGists',
    'AddNewGist',
    'EditGist',
    'DeleteGist',
    'ViewReports'
  ]
}