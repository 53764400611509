<template>
  <div id="UnauthorizedTenantPage" class="h-screen">
    <BaseLayout :withMenu="false">
      <template #mainContent>
        <span class="w-full block text-center text-2xl bg-base-200 rounded-xl h-full text-secondary pt-32">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-16 h-16 m-auto">
            <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
          </svg>

          <h1 class="text-white max-w-sm m-auto">
            {{ $t("message.unauthorizedTenant") }}
          </h1>
        </span>
      </template>
    </BaseLayout>
  </div>
</template>

<script>
import BaseLayout from '../../layouts/BaseLayout.vue'

export default {
  name: 'UnauthorizedTenantPage',
  props: {
  },
  components: {
    BaseLayout
  },
  data() {
    return {
    }
  },
  computed: {
  },
  async mounted() {
  },
  watch: {
  },
  methods: {
    backToHome() {
      parent.location.href = "/"
    }
  }
}
</script>

<style scoped>
</style>
