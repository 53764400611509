
import { PublicClientApplication } from '@azure/msal-browser';
//import User from './helper/User';

const msalInstance = new PublicClientApplication({
  auth: {
    clientId: process.env.VUE_APP_AZURE_CLIENT_ID,
    authority: process.env.VUE_APP_MSAL_AUTHORITY,
    redirectUri: process.env.VUE_APP_AZURE_REDIRECT_URI,
    postLogoutRedirectUri: process.env.VUE_APP_AZURE_REDIRECT_URI
  }
});

export default msalInstance;