<template>
  <div v-if="state.scenarios && state.scenarios.length > 0">
    <!-- <div class="text-xs ml-2 w-full items-center flex justify-center text-center">
      <div>{{ state.page+1 }} / {{ state.totalPages }}</div>
    </div> -->
    <div class="text-white flex flex-col gap-1">
      <ScenarioComponent v-for="scenario in state.scenarios" :key="scenario.id" :scenario="scenario" :clickOnEdit="() => { clickOnEdit(scenario) }" :clickOnRemove="() => { clickOnRemove(scenario) }"></ScenarioComponent>
    </div>
    <div class="flex items-center mt-3 mb-0 w-full text-center">
      <div class="flex w-full items-center justify-center gap-0.5" v-if="state.totalPages > -1">
        <div v-for="index in state.totalPages" :key="index">
          <button @click="setPage(index-1)" class="join-item btn btn-xs" :class="[{ 'btn-active': (index-1) == state.page }, { 'opacity-60': (index-1) != state.page }]">{{ index }}</button>
        </div>
      </div>
    </div>
  </div>
  <div class="text-xs alert" v-else>
    {{ $t("message.noScenarioAddedCurrently") }}
  </div>
</template>

<script setup>
  //import Utils from '../../../misc/Utils';
  import ScenarioComponent from './ScenarioComponent.vue';
  import Axios from '@/misc/axios.js'
  import { reactive, onMounted } from 'vue';
  import toast from '@/misc/toast.js'
  import store from '@/misc/vuex-store.js'

  const PAGE_SIZE = Math.min(Math.floor((window.outerHeight-300)/80), 20);

  const props = defineProps({
    responderId: {
      type: Number,
      default: null
    }
  });

  const state = reactive({
    scenarios: [],
    page: 0,
    totalPages: -1
  })

  async function clickOnRemove(scenario) {
    await Axios.delete(process.env.VUE_APP_FRONTENDV2_API_URL + '/scenario/' + scenario.id).then(() => {
      getScenarioPage();
      toast.error('Scenario removed!')
    });
  }

  function clickOnEdit(scenario) {
    store.commit('showSidebar', { type: 'editScenario', entity: scenario });
  }

  function setPage(index) {
    state.page = index;
    getScenarioPage();
  }

  async function getScenarioPage() {
    try { 
      let uri = process.env.VUE_APP_FRONTENDV2_API_URL + '/scenarios?page=' + state.page + '&size=' + PAGE_SIZE;
      if(props.responderId) {
        uri = process.env.VUE_APP_FRONTENDV2_API_URL + '/scenarios/with-responder/' + props.responderId + '?page=' + state.page + '&size=' + PAGE_SIZE;
      }
      
      const response = await Axios.get(uri, {
        onTokenRefreshed: () => {
          getScenarioPage()
        }
      });
      state.scenarios = response.data.data;
      state.totalPages = response.data.page.totalPages;
    }
    catch(e) {
      console.log("NETWORK ERROR WHILE LOADING SCENARIOS!", e)
    }
  }

  onMounted(() => {
    getScenarioPage()
  });

</script>