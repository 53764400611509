<template>
  <div id="PermissionDeniedPage" class="h-screen">
    <BaseLayout :withMenu="false">
      <template #leftButtons>
      </template>
      <template #mainContent>
        <span class="w-full block text-center text-2xl bg-base-200 rounded-xl h-full text-secondary pt-32">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-16 h-16 m-auto">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m0-10.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.249-8.25-3.286zm0 13.036h.008v.008H12v-.008z" />
          </svg>

          <h1 class="text-white max-w-sm m-auto">
            {{ $t("message.permissionDenied") }}
          </h1>

          <button class="btn btn-secondary btn-sm mt-4" @click="back">
            {{ $t("message.goBack") }}
          </button>
        </span>
      </template>
    </BaseLayout>
  </div>
</template>

<script>
import BaseLayout from '../../layouts/BaseLayout.vue'

export default {
  name: 'PermissionDeniedPage',
  props: {
  },
  components: {
    BaseLayout
  },
  data() {
    return {
    }
  },
  computed: {
  },
  async mounted() {
  },
  watch: {
  },
  methods: {
    back() {
      history.go(-1)
    }
  }
}
</script>

<style scoped>
</style>
