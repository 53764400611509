<template>
  <div id="TokenExpiredPage" class="h-screen">
    <BaseLayout :withMenu="false">
      <template #mainContent>
        <span class="w-full block text-center text-2xl bg-base-200 rounded-xl h-full text-secondary pt-32">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-16 h-16 m-auto">
            <path stroke-linecap="round" stroke-linejoin="round" d="M7.864 4.243A7.5 7.5 0 0119.5 10.5c0 2.92-.556 5.709-1.568 8.268M5.742 6.364A7.465 7.465 0 004.5 10.5a7.464 7.464 0 01-1.15 3.993m1.989 3.559A11.209 11.209 0 008.25 10.5a3.75 3.75 0 117.5 0c0 .527-.021 1.049-.064 1.565M12 10.5a14.94 14.94 0 01-3.6 9.75m6.633-4.596a18.666 18.666 0 01-2.485 5.33" />
          </svg>

          <h1 class="text-white max-w-sm m-auto">
            {{ $t("message.sessionExpired") }}
          </h1>

          <button class="btn btn-secondary btn-sm mt-4" @click="backToHome">
            {{ $t("message.loginAgain") }}
          </button>
        </span>
      </template>
    </BaseLayout>
  </div>
</template>

<script>
import BaseLayout from '../../layouts/BaseLayout.vue'

export default {
  name: 'TokenExpiredPage',
  props: {
  },
  components: {
    BaseLayout
  },
  data() {
    return {
    }
  },
  computed: {
  },
  async mounted() {
  },
  watch: {
  },
  methods: {
    backToHome() {
      parent.location.href = "/"
    }
  }
}
</script>

<style scoped>
</style>
