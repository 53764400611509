<template>
  <div class="LeafletMap h-full">
    <InvalidMapView v-if="state.isMapInvalidView" />
    <MapLoader v-if="state.loading" />
    <l-map 
      v-if="!state.isMapInvalidView"
      ref="map" 
      class="lMap !bg-white rounded-box !z-[1]"
      :zoom="-0.5" :center="[0, 0]" 
      :options="{ 
        attributionControl: false, 
        zoomControl: false, 
        zoomSnap: 0.5, 
        zoomDelta: 0.5, 
        minZoom: -2, 
        maxZoom: maxZoomLevel,
        bounds: state.mapImageBounds
      }" 
      :crs="state.crs" 
      @click="mapClick"
      @update:zoom="changedZoom"
      @ready="() => { mapReady() }"
    >
      <LControlZoom position="bottomright"></LControlZoom>
      <LControlAttribution position="bottomright" prefix=""></LControlAttribution>
      <LImageOverlay class="!z-[2]" v-if="state.floorMapUrl && state.mapImageBounds" :url="state.floorMapUrl" :bounds="state.mapImageBounds"></LImageOverlay>
      <slot name="mapContent"></slot>
    </l-map>
  </div>
</template>

<script setup>
  import { CRS } from "leaflet";
  import { LControlZoom, LControlAttribution, LImageOverlay, LMap } from '@vue-leaflet/vue-leaflet';
  import InvalidMapView from './InvalidMapView.vue'
  import MapLoader from './MapLoader.vue'
  import LDraw from 'leaflet-draw';
  import { reactive, computed, ref, nextTick, watch } from "vue";
  import store from '@/misc/vuex-store'
  import LeafletHelper from '@/misc/leafletHelper'

  const map = ref(null)

  const maxZoomLevel = computed(() => {
    return process.env.VUE_APP_MAP_MAX_ZOOM_VALUE
  })

  const props = defineProps({
    mapName: {
      type: [String],
      default: null
    },
    geometry: {
      type: [Object],
      default: null
    },
    onMapReady: {
      type: Function,
      default: () => {}
    },
    drawMode: {
      type: Boolean,
      default: false
    },
    featureGroup: {
      type: Object,
      default: null
    },
    onDrawReady: {
      type: Function,
      default: () => {}
    },
    onEditReady: {
      type: Function,
      default: () => {}
    },
    onDeleteReady: {
      type: Function,
      default: () => {}
    }
  });

  const state = reactive({
    crs: CRS.Simple,
    isMapInvalidView: false,
    floorMapUrl: null,
    mapDimensionsX: null,
    mapDimensionsY: null,
    mapImageBounds: null,
    drawControl: false
  });

  function changedZoom(zoomLevel) {
    store.commit('setZoomLevel', zoomLevel)
  }

  function mapClick(e) {
    LeafletHelper.mapClickConverter(props.geometry, state.mapDimensionsX, state.mapDimensionsY, e);
  }

  function mapReady() {
    loadMap();
  }

  function initDrawOnMap() {
    LDraw;
    if(state.drawControl) {
      state.drawControl.remove();
      state.drawControl = null;
    }

    state.drawControl = new window.L.Control.Draw({
      position: 'topright',
      draw: {
        polyline: false,
        polygon: true,   // disable or enable with true/false
        rectangle: true,
        circle: false,
        marker: false,
        circlemarker: false,
        toolbar: {
          buttons: {
            polygon: 'Poligon rajzolás'
          }
        }
      },
      edit: {
        featureGroup: props.featureGroup
      }
    });

    map.value.leafletObject.addControl(state.drawControl);
    
    map.value.leafletObject.on(window.L.Draw.Event.CREATED, (e) => {
      const layer = e.layer;

      if(layer.editing._poly) { // polygon
        props.onDrawReady(layer.editing._poly._latlngs[0])
      }
      if(layer.editing._shape) { // shape
        props.onDrawReady(layer.editing._shape._latlngs[0])
      }
    });

    map.value.leafletObject.on(window.L.Draw.Event.DELETED, (e) => {
      var layerIDsToRemove = [];
      for(var i in e.layers._layers) {
        var layer = e.layers._layers[i];
        layerIDsToRemove.push(layer.options.nrgmapId);
      }
      props.onDeleteReady(layerIDsToRemove)
    });

    map.value.leafletObject.on(window.L.Draw.Event.EDITED, (e) => {
      var layersToEdit = [];
      for(var i in e.layers._layers) {
        var layer = e.layers._layers[i];
        layersToEdit.push({
          id: layer.options.nrgmapId,
          displayName: layer.options.nrgmapName,
          latLngs: layer._latlngs[0]
        });
      }

      props.onEditReady(layersToEdit)
    });

    nextTick(() => {
      // these 2 lines will fix a Leaflet.Draw bug :)
      // the bug: https://github.com/Leaflet/Leaflet.draw/issues/678
      // solution ref: https://stackoverflow.com/questions/15775103/leaflet-draw-mapping-how-to-initiate-the-draw-function-without-toolbar
      document.querySelector('.leaflet-draw-edit-edit').click();
      if(document.querySelector('.leaflet-draw-actions-top li:last-child a')) {
        document.querySelector('.leaflet-draw-actions-top li:last-child a').click();
      }
    })
  }

  watch(() => props.drawMode, (newValue) => {
    if(newValue) {
      initDrawOnMap()
    }
  })

  watch(() => props.mapName, () => {
    loadMap()
  })

  async function loadMap() {
    if(!props.mapName) {
      state.isMapInvalidView = true;
      return ;
    }

    state.loading = true;
    state.floorMapUrl = 'data:image/jpeg;base64,' + await LeafletHelper.fetchImage(props.mapName)
    var dimensions = await LeafletHelper.getImageDimensions(state.floorMapUrl);
    state.mapDimensionsX = dimensions.w;
    state.mapDimensionsY = dimensions.h;
    state.mapImageBounds = [[0, 0], [state.mapDimensionsY, state.mapDimensionsX]];

    nextTick(() => {
      if(map.value.leafletObject) {
        setTimeout(() => {
          map.value.leafletObject.setView([state.mapDimensionsY/2, state.mapDimensionsX/2], -0.5, {
            animate: false
          })
          map.value.leafletObject.setView([state.mapDimensionsY/2, state.mapDimensionsX/2], -1, {
            animate: false
          })
        })
      }

      props.onMapReady(state.mapDimensionsX, state.mapDimensionsY)
      state.loading = false;
    })
  }

</script>