import { createWebHistory, createRouter } from "vue-router"

import MapPage from '../pages/MapPage.vue'
import SiteDesignSelectorPage from '../pages/SiteDesignSelectorPage.vue'
import ZoneEditorPage from '../pages/ZoneEditorPage.vue'
import DevicesPage from '../pages/DevicesPage.vue'
//import HeatmapPage from '../pages/HeatmapPage.vue'
import User from '../misc/User'
import PermissionDeniedPage from '../pages/PermissionDeniedPage/PermissionDeniedPage.vue'
import UnauthorizedTenantPage from '../pages/UnauthorizedTenantPage/UnauthorizedTenantPage.vue'
import TokenExpiredPage from '../pages/TokenExpiredPage/TokenExpiredPage.vue'
import TemperatureMapPage from '../pages/TemperatureMapPage.vue'
import ResponderPage from '../pages/ResponderPage.vue'
import msalInstance from './msalInstance';
import RespondersPage from '../pages/RespondersPage.vue';
//import msalHelper from './msalHelper';
import ReportsPage from "../pages/ReportsPage.vue";

async function requireAuthAndValidTenant(to, from, next) {
  if(User.isLoggedIn()) { // already logged in
    const allowedTenants = process.env.VUE_APP_ALLOWED_TENANTS.split(',');
    if(allowedTenants.includes(User.getUserTenantId())) {
      if(!to.meta.permission || User.hasPermission(to.meta.permission)) {
        next();
      } 
      else {
        next('/error/permission-denied');
      }
    }
    else {
      next('/error/unauthorized-tenant');
    }
  } else {
    const response = await msalInstance.handleRedirectPromise();
    //msalHelper.fetchMainToken(msalInstance);

    if(response) { // handling login response
      User.login(response.accessToken, response.idToken, response.idTokenClaims.roles)
      //msalHelper.fetchGraphToken(msalInstance);
      next();
    }
    else { // invoke login
      await msalInstance.loginRedirect({
        scopes: [process.env.VUE_APP_AZURE_API_TOKEN_REQUEST]
      });
    }
  }
}

let router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/reports',
      component: ReportsPage,
      name: 'powerBiReportPage',
      meta: { 
        requiresAuth: true,
        permission: 'ViewReports'
      },
      beforeEnter: requireAuthAndValidTenant
    },
    {
      path: '/',
      component: SiteDesignSelectorPage,
      name: 'siteDesignSelector',
      meta: { 
        requiresAuth: true,
        permission: 'MainMap'
      },
      beforeEnter: requireAuthAndValidTenant
    },
    {
      path: '/map/:siteDesignId',
      component: MapPage,
      name: 'map',
      meta: { 
        requiresAuth: true,
        permission: 'MapView'
      },
      beforeEnter: requireAuthAndValidTenant
    },
    {
      path: '/temperature-map/:siteDesignId',
      component: TemperatureMapPage,
      name: 'temperatureMap',
      meta: { 
        requiresAuth: true,
        permission: 'TemperatureMapView'
      },
      beforeEnter: requireAuthAndValidTenant
    },
    // {
    //   path: '/map/:siteDesignId/heatmap',
    //   component: HeatmapPage,
    //   name: 'heatmap',
    //   meta: { 
    //     requiresAuth: true,
    //     permission: 'Heatmap'
    //   },
    //   beforeEnter: requireAuthAndValidTenant
    // },
    {
      path: '/map/:siteDesignId/devices',
      component: DevicesPage,
      name: 'devices',
      meta: { 
        requiresAuth: true,
        permission: 'Devices'
      },
      beforeEnter: requireAuthAndValidTenant
    },
    {
      path: '/map/:siteDesignId/edit/:type',
      component: ZoneEditorPage,
      name: 'zoneEditor',
      meta: { 
        requiresAuth: true,
        permission: 'ZoneAdmin'
      },
      beforeEnter: requireAuthAndValidTenant
    },
    {
      path: '/error/permission-denied',
      component: PermissionDeniedPage,
      name: 'permissionDenied',
      meta: { 
        requiresAuth: false,
        permission: null
      }
    },
    {
      path: '/error/unauthorized-tenant',
      component: UnauthorizedTenantPage,
      name: 'unauthorizedTenant',
      meta: { 
        requiresAuth: false,
        permission: null
      }
    },
    {
      path: '/responders',
      component: RespondersPage,
      name: 'respondersPage',
      meta: { 
        requiresAuth: false,
        permission: null
      }
    },
    {
      path: '/responder/:responderId',
      component: ResponderPage,
      name: 'responderPage',
      meta: { 
        requiresAuth: false,
        permission: null
      }
    },
    {
      path: '/error/token-expired',
      component: TokenExpiredPage,
      name: 'tokenExpired',
      meta: { 
        requiresAuth: false,
        permission: null
      }
    }
  ]
});

export default router;