<template>
  <div id="ZoneEditorView" class="h-full rounded-box bg-white relative">
    <ToastMessage v-if="toast" :toast="toast" />
    <LeafletMap class="lMap" v-if="(selectedSiteDesign != null)" ref="map" :mapName="selectedSiteDesign.map" :geometry="selectedSiteDesign.geometry" :onMapReady="onMapReady" :drawMode="drawMode" :featureGroup="featureGroup" :onDrawReady="onDrawReady" :onDeleteReady="onDeleteReady" :onEditReady="onEditReady">
      <template #mapContent v-if="mapReady">
        <LFeatureGroup @ready="readyFeatureGroup">
          <LPolygon @click="() => { editZone(zone.id) }" :options="{nrgmapId:zone.id, nrgmapName: zone.displayName}" v-for="(zone, key) in zones" :key="key" :lat-lngs="mapServerPointsToPolygonData(zone.points)"  :fillOpacity="getFillOpacity()" :fillColor="getFillColor()" :color="getLineColor()" :opacity="getLineOpacity()" :weight="2">
            <l-tooltip :options="{permanent: true, direction:'center', interactive: false}">
              <EditorPolygonContent :zoneName="zone.displayName" :zoneId="zone.id" />
            </l-tooltip>
          </LPolygon>
        </LFeatureGroup>
      </template>
    </LeafletMap>
  </div>
</template>

<script>

import { LFeatureGroup, LPolygon, LTooltip } from '@vue-leaflet/vue-leaflet';
import "leaflet-draw/dist/leaflet.draw.css";
import ToastMessage from '../../components/ToastMessage.vue'
import LeafletMap from '../../components/LeafletMap2.vue'
import EditorPolygonContent from './EditorPolygonContent.vue';
import Utils from '../../misc/Utils';
import Axios from '@/misc/axios.js'

export default {
  name: 'ZoneEditorView',
  props: {
    selectedSiteDesign: {
      type: [Object],
      default: null
    }
  },
  components: {
    LPolygon,
    LTooltip,
    LFeatureGroup,
    ToastMessage,
    LeafletMap,
    EditorPolygonContent
  },
  data() {
    return {
      loading: false,
      currentDrawedMarkers: [],
      mapDimensionsX: null,
      mapDimensionsY: null,
      zones: [],
      zoneGroups: [],
      drawControl: null,
      featureGroup: null,
      toast: null,
      toastInterval: null,
      drawMode: false,
      mapReady: false,
      needToEnableDisableEditMode: true,
      isNameEditorModalOpen: false,
      editedPolygonId: null,
      editedPolygonName: ''
    }
  },
  computed: {
  },
  mounted() {
    if(this.selectedSiteDesign) {
      this.fetchZones();
    }
  },
  watch: {
    selectedSiteDesign() {
      this.fetchZones();
    },
    featureGroup() {
      this.drawMode = true;
    },
    editedPolygonId() {
      if(this.editedPolygonId) {
        var editedPolygon = this.getPolygonById(this.editedPolygonId);
        this.editedPolygonName = editedPolygon.displayName;
      }
    }
  },
  methods: {
    getLineColor() { return process.env.VUE_APP_POLYGON_LINE_COLOR; },
    getFillColor() { return process.env.VUE_APP_POLYGON_FILL_COLOR; },
    getLineOpacity() { return 0.5 },
    getFillOpacity() { return 0.3 },
    editZone(zoneId) {
      this.$store.commit('showSidebar', {
        type: 'zoneUpdate',
        entity: this.getZoneById(zoneId),
        params: {
          onSave: (updatedZoneData) => {
            this.updateZoneData(updatedZoneData)
          }
        }
      })
    },
    getZoneById(zoneId) {
      let zone = null;
      for(var i in this.zones) {
        if(this.zones[i].id == zoneId) {
          zone = this.zones[i];
        }
      }

      if(zone) {
        let zoneGroups = [];
        for(let i in zone.zoneGroupIds) {
          const zg = this.getZoneGroupById(zone.zoneGroupIds[i]);
          if(zg) {
            zoneGroups.push({
              id: zg.id,
              text: zg.displayName
            })
          }
        }
        zone.zoneGroups = zoneGroups;
      }

      return zone;
    },
    updateZoneData(updatedZoneData) {
      if(!updatedZoneData || !updatedZoneData.id) { return; }
      this.fetchZones();
    },
    getZoneGroupById(zoneGroupId) {
      let zoneGroup = null;
      for(let i in this.zoneGroups) {
        if(this.zoneGroups[i].id == zoneGroupId) {
          zoneGroup = {
            id: this.zoneGroups[i].id,
            displayName: this.zoneGroups[i].displayName
          };
        }
      }

      return zoneGroup;
    },
    saveZoneName() {
      var editedPolygon = this.getPolygonById(this.editedPolygonId);
      editedPolygon.displayName = this.editedPolygonName;
      this.upsertPolygon(editedPolygon);
      this.editedPolygonId = null; this.editedPolygonName = ''; this.isNameEditorModalOpen = false;
    },
    upsertPolygon(polygonData, preventReload) {
      Axios.post(process.env.VUE_APP_ADMINV2_API_URL + '/zone/create', polygonData, {
        onTokenRefreshed: () => {
          this.upsertPolygon(polygonData, preventReload)
        }
      }).then(() => {
        this.showToast('success', this.$t("message.successfulSave"));
        if(!preventReload) {
          parent.location.reload()
        }
      })
      .catch((err) => {
        console.log(err)
        this.showToast('error', this.$t("message.notSuccessfulSave"));
        if(!preventReload) {
          parent.location.reload()
        }
      })
    },
    onMapReady(mapDimensionsX, mapDimensionsY) {
      this.mapDimensionsX = mapDimensionsX
      this.mapDimensionsY = mapDimensionsY
      this.mapReady = true;
    },
    readyFeatureGroup(featureGroup) {
      this.featureGroup = featureGroup;
    },
    showToast(type, message) {
      if(this.toastInterval) {
        window.clearTimeout(this.toastInterval);
      }

      this.toast = {
        type: type,
        message: message
      };

      var vm = this;
      vm.toastInterval = window.setTimeout(() => {
        vm.toast = null;
      }, 3000)
    },
    async fetchZones() {
      const response = await Utils.fetchZones(this.selectedSiteDesign.id);
      this.zones = response.zones;
      this.zoneGroups = response.zone_groups;
    },
    mapServerPointsToPolygonData(points) {
      var result = [];
      
      for(var i in points) {
        result.push(this.transformPointsToMap(points[i].x, points[i].y))
      }

      return result;
    },
    transformPointsToMap(y, x) {
      if(!this.selectedSiteDesign.geometry) {
        return;
      }

      return Utils.transformPointsToMap(y, x, this.mapDimensionsX, this.mapDimensionsY, this.selectedSiteDesign.geometry)
    },
    transformPointsToServer(y, x) {
      if(!this.selectedSiteDesign.geometry) { return; }
      return Utils.transformPointsToServer(y, x, this.mapDimensionsX, this.mapDimensionsY, this.selectedSiteDesign.geometry)
    },
    async onEditReady(editedPolygons) {
      var hasError = false;
      for(var i in editedPolygons) {
        var editedPolygon = editedPolygons[i]
        var finalLatLngs = [];
        for(var j in editedPolygon.latLngs) {
          var transformedLatLngs = this.transformPointsToServer(editedPolygon.latLngs[j].lng, editedPolygon.latLngs[j].lat)
          finalLatLngs.push({
            x: transformedLatLngs[1],
            y: transformedLatLngs[0]
          })
        }

        try {
          await Axios.post(process.env.VUE_APP_ADMINV2_API_URL + '/zone/create', {
            id: editedPolygon.id,
            displayName: editedPolygon.displayName,
            points: finalLatLngs,
            siteDesignId: this.selectedSiteDesign.id
          }, {
            onTokenRefreshed: () => {
              this.onEditReady(editedPolygons)
            }
          });
        }
        catch(err) {
          console.log(err);
          hasError = true;
        }
      }

      if(!hasError) {
        this.showToast('success', this.$t("message.successfulSave"));
      }
      else {
        this.showToast('error', this.$t("message.saveError"));
      }

      parent.location.reload();
    },
    onDrawReady(latLngs) {
      var finalLatLngs = [];
      for(var i in latLngs) {
        var transformedLatLngs = this.transformPointsToServer(latLngs[i].lng, latLngs[i].lat)
        finalLatLngs.push({
          x: transformedLatLngs[1],
          y: transformedLatLngs[0]
        })
      }

      this.upsertPolygon({
        id: null,
        displayName: this.$t("message.anonymus"),
        points: finalLatLngs,
        siteDesignId: this.selectedSiteDesign.id
      });
    },
    async onDeleteReady(layerIDsToRemove) {
      var hasErr = false;

      try {
        for(var i in layerIDsToRemove) {
          await Axios.delete(process.env.VUE_APP_ADMINV2_API_URL + '/zone/' + layerIDsToRemove[i], {
            onTokenRefreshed: () => {
              this.onDeleteReady(layerIDsToRemove)
            }
          });
        }
      }
      catch(err) {
        hasErr = true;
      }

      if(!hasErr) {
        this.showToast('success', this.$t("message.deleteSuccessful"));
      }
      else {
        this.showToast('error', this.$t("message.deleteNotSuccessful"));
      }

      parent.location.reload()
    }
  }
}
</script>

<style scoped>
.lMap >>> .leaflet-tooltip {
  background: transparent !important;
  border: none !important;
  border-radius: 6px;
  box-shadow: none;
  color: white;
  font-size: 18px;
  min-width: 170px;
}
/* 
.lMap >>> .leaflet-interactive:hover {
  fill-opacity: 0.7;
  -webkit-transition: fill-opacity 0.5s;
  transition: fill-opacity 0.5s;
} */


.lMap >>> .leaflet-tooltip-pane:hover, .lMap >>> .leaflet-tooltip:hover {
  z-index: 9999;
}

</style>
