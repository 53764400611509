import axios from 'axios';
import User from '@/misc/User.js';
import msalHelper from '@/misc/msalHelper.js';
import msalInstance from '@/misc/msalInstance.js';

// Sending JWT if its in place
axios.interceptors.request.use(
  (config) => {
    if(config.url.indexOf('https://graph.microsoft.com/') > -1) {
      const token = User.getGraphAccessToken();
      if (token && !config.headers['Authorization']) {
        config.headers['Authorization'] = 'Bearer ' + token;
      }
    }
    else {
      const token = User.getAccessToken();
      if (token && !config.headers['Authorization']) {
        config.headers['Authorization'] = 'Bearer ' + token;
      }
    }
    return config;
  },
  (error) => {
    Promise.reject(error)
  }
);

const invalidTokenStatus = 401;
const invalidTokenStatusGraph = 401;
function pushError(title, message) {
  console.log("AXIOS ERROR: ", title, message)
}

let refreshCounter = 0;

axios.interceptors.response.use(
  response => response, 
  error => {
    var isGraphRequest = error.config.url.indexOf('https://graph.microsoft.com/') > -1;

    if(!error.response && process.env.VUE_APP_CORS_ERROR_MEANS_PERMISSION_DENIED == "true") { // possibly CORS error
      msalHelper.fetchMainToken(msalInstance, () => {
        if(refreshCounter > 3) {
          return;
        }

        if(error && error.config && error.config.onTokenRefreshed) {
          error.config.onTokenRefreshed();
          refreshCounter++;
        }
      })
    }

    if(isGraphRequest && error.response.status == invalidTokenStatusGraph) { // graph token expired
      msalHelper.fetchGraphToken(msalInstance, () => {
        if(error.response && error.response.config && error.response.config.onTokenRefreshed) {
          error.response.config.onTokenRefreshed();
        }
      })
    }

    if(!isGraphRequest && error.response && error.response.status == invalidTokenStatus) { // main token expired
      msalHelper.fetchMainToken(msalInstance, () => {
        if(error.response && error.response.config && error.response.config.onTokenRefreshed) {
          error.response.config.onTokenRefreshed();
        }
      })
    }

    if(error && error.response) {
      if(error.response.config && !error.response.config.avoidToastOnError) {
        pushError('Error', error.message);
      }
      return Promise.reject(error);
    }

    if(error.response && error.response.config && error.response.config.customErrorHandlers) {
      for(var i in error.response.config.customErrorHandlers) {
        if(error.response.status == i) {
          error.response.config.customErrorHandlers[i](error);
          return Promise.reject(error);
        }
      }
    }
    
    if(error.response.status >= 100 && error.response.status < 199) {
      console.info("INFORMATIONAL_RESPONSE");
      return Promise.reject(error);
    }
    if(error.response.status >= 300 && error.response.status < 400) {
      console.info("REDIRECTION_RESPONSE");
      return Promise.reject(error);
    }
    if(error.response.status >= 400 && error.response.status < 500) {
      console.info("CLIENT_ERROR");
      if(!((error.response.config && error.response.config.avoidToastOnError) || (isGraphRequest && error.response.status == invalidTokenStatusGraph) || (!isGraphRequest && error.response.status == invalidTokenStatus))) {
        pushError('Client error (' + error.response.status + ')', error.response.body ? error.response.body : "Unknown error.")
      }
      return Promise.reject(error);
    }
    if(error.response.status >= 500) {
      console.info("SERVER_ERROR");
      if(!error.response.config || !error.response.config.avoidToastOnError) {
        pushError('Server error (' + error.response.status + ')', error.response.body ? error.response.body : "Unknown error.")
      }
      return Promise.reject(error);
    }
  }
);

export default axios;