<template>
  <div class="p-0 h-full overflow-y-scroll">
    <div class="p-5 pb-2 mr-2 flex flex-col grow">
      <div class="flex flex-row items_center justify-between mb-3">
        <h2 class="text-base font-sans font-semibold uppercase text-white flex flex-row items-center justify-center gap-2">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
            <path strokeLinecap="round" strokeLinejoin="round" d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z" />
          </svg>
          <span class="text-white mr-1">{{ $t("message.scenarios") }}</span>
        </h2>
        <a href="javascript:void(null)" class="hover:text-secondary" @click="hideSidebar">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </a>
      </div>

      <div class="">
        <ListScenarios />
      </div>

      <div class="form-control w-full pt-5" @click="saveScenario">
        <button data-v-2c58454a="" type="button" class="btn btn-secondary" @click="store.commit('showSidebar', { type: 'scenarioTypeSelector' });">
          {{ $t("message.newScenario") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
  import store from '@/misc/vuex-store';
  import ListScenarios from './ScenariosSidebarComponents/ListScenarios.vue';

  function hideSidebar() {
    store.commit('hideSidebar');
  }
</script>