<template>

  <div class="form-control w-full ">
    <label class="label">
      <span class="label-text">{{ $t("message.selectTime") }}</span>
      <span class="label-text-alt"></span>
    </label>
    <TimePickerComponent v-model="state.time" class="w-full" :validation="Validations.time" />
  </div>
</template>

<script setup>
  import TimePickerComponent from '@/components/ScenarioTypes/CommonComponents/TimePickerComponent.vue';
  import { onMounted, reactive, watch } from 'vue';
  import Validations from './Validations.js';

  const props = defineProps({
    conditionsChanged: {
      type: Function,
      required: true
    },
    defaultValue: {
      type: Object,
      required: false,
      default: null
    }
  });

  const state = reactive({
    time: ''
  });

  function validateAll() {
    let keys = Object.keys(Validations);
    for(let i = 0; i < keys.length; i++) {
      if(!Validations[keys[i]].test(state[keys[i]])) {
        return false;
      }
    }
    return true;
  }

  function emitConditionsChanged() {
    if(!validateAll()) { return; }

    props.conditionsChanged({
      hour: state.time.split(':')[0],
      minute: state.time.split(':')[1]
    });
  }

  watch(() => state.time, () => { emitConditionsChanged(); });

  onMounted(() => {
    if(props.defaultValue) {
      state.time = props.defaultValue.hour + ':' + props.defaultValue.minute;
    }
  })

</script>