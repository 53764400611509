import { createStore } from 'vuex'
import moment from 'moment'
import localStorageService from '../services/LocalStorageService/LocalStorageService.js'
import WeatherIcons from './WeatherIcons.js';
import VueCookie from 'vue-cookie';

export default createStore({
  state() {
    return {
      access_token: null,
      id_token: null,
      graph_access_token: null,
      graph_id_token: null,
      roles: null,
      siteDesigns: null,
      selectedSiteDesign: null,
      selectedAsset: localStorageService.getSelectedAsset() ? localStorageService.getSelectedAsset() : null,
      weatherData: [],
      weatherDate: null,
      currentDate: moment().format('YYYY-MM-DD'),
      sidebarEntity: null,
      zoomLevel: null,
      theme: 'dark',
      siteDesignsLoaded: false,
      appConfig: {
        gistTypes: [],
        zoneTypes: [],
        adapterTypes: []
      },
      language: 'en'
    }
  },
  mutations: {
    setLanguage(state, language) {
      state.language = language;
      VueCookie.set('language', language);
    },
    setAppConfig(state, appConfig) {
      state.appConfig.gistTypes = appConfig.gistTypes;
      state.appConfig.zoneTypes = appConfig.zoneTypes;
      state.appConfig.adapterTypes = appConfig.adapters;
    },
    setAccessToken(state, access_token) {
      VueCookie.set('access_token', access_token);
      state.access_token = access_token
    },
    clearAccessToken(state) {
      VueCookie.delete('access_token');
      state.access_token = null;
    },
    setIdToken(state, id_token) {
      VueCookie.set('id_token', id_token);
      state.id_token = id_token;
    },
    clearIdToken(state) {
      VueCookie.delete('id_token');
      state.id_token = null;
    },
    setGraphAccessToken(state, graph_access_token) {
      VueCookie.set('graph_access_token', graph_access_token);
      state.graph_access_token = graph_access_token
    },
    clearGraphAccessToken(state) {
      VueCookie.delete('graph_access_token');
      state.graph_access_token = null;
    },
    setGraphIdToken(state, graph_id_token) {
      VueCookie.set('graph_id_token', graph_id_token);
      state.graph_id_token = graph_id_token
    },
    clearGraphIdToken(state) {
      VueCookie.delete('graph_id_token');
      state.graph_id_token = null;
    },
    setRoles(state, roles) {
      VueCookie.set('roles', roles);
      state.roles = roles;
    },
    clearRoles(state) {
      VueCookie.delete('roles');
      state.roles = null;
    },
    logout(state) {
      VueCookie.delete('access_token');
      VueCookie.delete('id_token');
      VueCookie.delete('roles');
      state.access_token = null
      state.id_token = null;
      state.roles = null;
    },
    setTheme(state, theme) {
      state.theme = theme;
    },
    setSiteDesigns(state, siteDesigns) {
      state.siteDesigns = siteDesigns;
      if(siteDesigns) {
        state.siteDesignsLoaded = true;
      }
    },
    setSelectedAsset(state, assetId) {
      localStorageService.setSelectedAsset(assetId);
      state.selectedAsset = assetId;
    },
    clearSelectedAsset(state, assetId) {
      if(localStorageService.getSelectedAsset() == assetId) {
        localStorageService.clearSelectedAsset();
        state.selectedAsset = null;
      }
    },
    setSelectedSiteDesign(state, siteDesign) {
      state.selectedSiteDesign = siteDesign
    },
    setWeatherData(state, weatherData, weatherDate) {
      state.weatherData = weatherData
      state.weatherDate = weatherDate
    },
    setCurrentDate(state, currentDate) {
      state.currentDate = currentDate
    },
    showSidebar(state, sidebarEntity) {
      state.sidebarEntity = sidebarEntity
    },
    hideSidebar(state) {
      state.sidebarEntity = null
    },
    setZoomLevel(state, zoomLevel) {
      state.zoomLevel = zoomLevel
    }
  },
  getters: {
    getLanguage(state) {
      return state.language;
    },
    getGistTypes(state) {
      return state.appConfig.gistTypes;
    },
    getAdapterTypes(state) {
      return state.appConfig.adapterTypes;
    },
    getZoneTypes(state) {
      return state.appConfig.zoneTypes;
    },
    getAccessToken: (state) => {
      return state.access_token;
    },
    getIdToken: (state) => {
      return state.id_token;
    },
    getRoles: (state) => {
      return state.roles;
    },
    getGraphAccessToken: (state) => {
      return state.graph_access_token;
    },
    getGraphIdToken: (state) => {
      return state.graph_id_token;
    },
    getSiteDesigns(state) {
      return state.siteDesigns;
    },
    getTheme(state) {
      return state.theme;
    },
    getSelectedAsset(state) {
      if(localStorageService.getSelectedAsset()) {
        state.selectedAsset = localStorageService.getSelectedAsset()
      }

      return state.selectedAsset;
    },
    getSelectedSiteDesign(state) {
      return state.selectedSiteDesign
    },
    getCurrentDate(state) {
      return state.currentDate
    },
    getSiteDesignsLoaded(state) {
      return state.siteDesignsLoaded
    },
    getWeatherData: (state) => (siteDesignId) => {
      for(let i in state.siteDesigns) {
        if(state.siteDesigns[i].id == siteDesignId) {
          if(!state.siteDesigns[i].currentWeatherGist) { return null }

          let cData = state.siteDesigns[i].currentWeatherGist
          var cIcon = WeatherIcons[cData.weatherIcon];

          if(cIcon) {
            if(!(cData.weatherIcon > 699 && cData.weatherIcon < 800) && !(cData.weatherIcon > 899 && cData.weatherIcon < 1000)) {
              cData.iconText = 'day-' + WeatherIcons[cData.weatherIcon].icon;
            }
            else {
              cData.iconText = WeatherIcons[cData.weatherIcon].icon;
            }
          }
          return cData
        }
      }

      return null;
    },
    getSiteDesignForMap: (state) => (siteDesignId) => {
      for(let i in state.siteDesigns) {
        if(state.siteDesigns[i].id == siteDesignId) {
          let cPresenceData = state.siteDesigns[i].currentPresenceGist ? state.siteDesigns[i].currentPresenceGist : 0
          let cNumberOfWorkplacesData = state.siteDesigns[i].currentPresenceGist ? state.siteDesigns[i].currentPresenceGist : 0
          let cDisplayNameData = state.siteDesigns[i].displayName ? state.siteDesigns[i].currentPresenceGist : "N/A"
          return {
            cPresenceData: cPresenceData,
            cNumberOfWorkplacesData: cNumberOfWorkplacesData,
            cDisplayNameData: cDisplayNameData
          }
        }
      }

      return 0;
    },
    getPresenceData: (state) => (siteDesignId) => {
      for(let i in state.siteDesigns) {
        if(state.siteDesigns[i].id == siteDesignId) {
          let cData = state.siteDesigns[i].currentPresenceGist
          return cData ? cData : 0
        }
      }

      return 0;
    },
    getNumberOfWorkplacesData: (state) => (siteDesignId) => {
      for(let i in state.siteDesigns) {
        if(state.siteDesigns[i].id == siteDesignId) {
          let cData = state.siteDesigns[i].numberOfWorkplaces
          return cData ? cData : 0
        }
      }

      return 0;
    },
    getNameData: (state) => (siteDesignId) => {
      for(let i in state.siteDesigns) {
        if(state.siteDesigns[i].id == siteDesignId) {
          let cData = state.siteDesigns[i].name
          return cData ? cData : "N/A"
        }
      }

      return 0;
    },
    getSidebarEntity(state) {
      return state.sidebarEntity
    },
    getZoomLevel(state) {
      return state.zoomLevel
    }
  },
  actions: {
  },
});