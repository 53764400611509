<template>
  <input
    v-model="state.data"
    class="input input-bordered h-10 w-full"
    :class="[{ 'border-error': !isValid }]"
    :disabled="props.disabled"
    :placeholder="props.placeholder"
    type="time"
    :step="props.step"
  />
</template>

<script setup>
  import { reactive, defineEmits, watch, computed } from 'vue';

  const props = defineProps({
    modelValue: {
      required: true
    },
    disabled: Boolean,
    placeholder: String,
    dateType: String,
    step: {
      type: Number,
      default: 60000,
    },
    validation: {
      type: RegExp,
      default: /.*/i
    }
  });

  watch(() => props.modelValue, (newValue) => {
    state.data = newValue;
  });

  const emit = defineEmits(['update:modelValue'])
  const state = reactive({
    data: ''
  });

  watch(() => state.data, (newValue) => {
    emit('update:modelValue', newValue)
  });

  const isValid = computed(() => {
    return props.validation.test(state.data);
  });
</script>
