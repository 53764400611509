<template>
  <div id="MainMenu" class="flex flex-col">

    <div class="divider flex-0 mt-1 mb-1"></div>

    <div class="tooltip tooltip-hover tooltip-right mb-2" data-tip="Zónatérkép" @click="changeType('zone')">
      <button :class="(route.name == 'map') ? 'btn btn-sm btn-secondary btn-circle flex-0' : 'btn btn-sm btn-secondary btn-outline btn-circle flex-0'">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
          <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 7.125C2.25 6.504 2.754 6 3.375 6h6c.621 0 1.125.504 1.125 1.125v3.75c0 .621-.504 1.125-1.125 1.125h-6a1.125 1.125 0 01-1.125-1.125v-3.75zM14.25 8.625c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v8.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-8.25zM3.75 16.125c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v2.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-2.25z" />
        </svg>
      </button>
    </div>

    <!-- <div class="tooltip tooltip-hover tooltip-right mb-2" data-tip="Hőtérkép nézet" @click="changeType('heatmap')">
      <button :class="(route.name == 'heatmap') ? 'btn btn-sm btn-secondary btn-circle flex-0' : 'btn btn-sm btn-secondary btn-outline btn-circle flex-0'">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
          <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
        </svg>
      </button>
    </div>
     -->

    <div class="tooltip tooltip-hover tooltip-right mb-2" data-tip="Hőmérsékleti térkép" @click="changeType('temperatureMap')">
      <button :class="(route.name == 'temperatureMap') ? 'btn btn-sm btn-secondary btn-circle flex-0' : 'btn btn-sm btn-secondary btn-outline btn-circle flex-0'">
        ℃
      </button>
    </div>


    <div class="tooltip tooltip-hover tooltip-right" v-if="User.hasPermission('Devices')" data-tip="Eszköztérkép" @click="changeType('devices')">
      <button :class="(route.name == 'devices') ? 'btn btn-sm btn-secondary btn-circle flex-0' : 'btn btn-sm btn-secondary btn-outline btn-circle flex-0'">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
          <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
        </svg>
      </button>
    </div>

    <div class="divider flex-0 mt-1 mb-1" v-if="User.hasPermission('ZoneAdmin')"></div>

    <div class="tooltip tooltip-hover tooltip-right mb-2" v-if="User.hasPermission('ZoneAdmin')" data-tip="Zóna admin" @click="changeType('zone_admin')">
      <button :class="(route.name == 'zoneEditor' && route.params.type == 'default') ? 'btn btn-sm btn-secondary btn-circle flex-0' : 'btn btn-sm btn-secondary btn-outline btn-circle flex-0'">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
        </svg>
      </button>
    </div>

  </div>
</template>

<script setup>
  import User from '@/misc/User'
  import { useRoute } from 'vue-router'; const route = useRoute();
  import router from '@/misc/router'

  function changeType(type) {
    if(!route.params.siteDesignId) {
      return ;
    }

    switch(type) {
      case 'zone':
        router.push({ name: 'map', params: { siteDesignId: route.params.siteDesignId } })
        break;
      case 'devices':
        router.push({ name: 'devices', params: { siteDesignId: route.params.siteDesignId } })
        break;
      case 'zone_admin':
        router.push({ name: 'zoneEditor', params: { siteDesignId: route.params.siteDesignId, type: 'default' } })
        break;
      case 'temperature_zone_admin':
        router.push({ name: 'zoneEditor', params: { siteDesignId: route.params.siteDesignId, type: 'temperature' } })
        break;
      case 'heatmap':
        router.push({ name: 'heatmap', params: { siteDesignId: route.params.siteDesignId } })
        break;
      case 'temperatureMap':
        router.push({ name: 'temperatureMap', params: { siteDesignId: route.params.siteDesignId } })
        break;
    }
  }
</script>

<style scoped>
</style>
