<template>
  <div class="toast absolute z-[9999] top-0 right-10">
    <div :class="'alert alert-' + toast.type + ' text-white'">
      <div>
        <span>{{ toast.message }}</span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ToastMessage',
  props: {
    toast: {
      type: [Object],
      default: () => {}
    }
  },
  data() {
    return {
    }
  },
  computed: {
  },
  components: {
  },
  mounted() {
  },
  watch: {
  },
  methods: {
  }
}
</script>

<style scoped>
</style>
