
<template>
  <div id="SDSelectorModal">
    <input type="checkbox" id="maps-modal" class="modal-toggle" v-model="isModalShown" />
    <div class="modal cursor-pointer">
      <div v-if="currentSiteDesign != null" class="modal-box h-full max-w-5xl overflow-visible">
        <label for="maps-modal" class="btn btn-sm btn-circle absolute -right-3 -top-3">✕</label>
        <div class="flex w-full h-full">
          <div class="grid h-full md:w-1/4 card bg-base-300 rounded-box hidden md:block">
            <l-map v-if="(currentSiteDesign.latitude && currentSiteDesign.longitude)" :options="{ zoomControl: false, scrollWheelZoom: false, doubleClickZoom: false, dragging: false, tap: false }" :zoom="15" :center="[currentSiteDesign.latitude, currentSiteDesign.longitude]" class="rounded-box">
              <l-tile-layer :url="'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'"></l-tile-layer>
              <LMarker :lat-lng="[currentSiteDesign.latitude, currentSiteDesign.longitude]"></LMarker>
            </l-map>
          </div>
          <div class="divider divider-horizontal w-0 hidden md:block"></div>
          <div class="self-start w-full md:w-3/4 h-full overflow-scroll">
            <div class="flex flex-row items-center mb-2">
              <div class="flex flex-col grow">
                <h2 class="text-xl font-bold uppercase mb-1 text-secondary">{{ currentSiteDesign.name }}</h2>
                <h2 class="text-sm text-info-200 inline-block">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#e18330" class="w-5 h-5 inline-block">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                  </svg>
                  {{ currentSiteDesign.siteNameHierarchy }}
                </h2>
              </div>
              <div class="grow-0 flex flex-row items-center">
                <i :class="'wi wi-' + weatherData.iconText + ' text-magenta text-lg mr-2 flex-0'"></i>
                <span class="font-bold">{{ Math.round(weatherData.temperature) }}°C</span>
              </div>
              <div class="tooltip tooltip-left" :data-tip="$t('message.noViewImage')">
                <button disabled class="btn btn-sm !bg-[#e18330] !opacity-30 ml-3 !text-white">
                  {{ $t("message.lidarView") }}
                </button>
              </div>
            </div>
            <div v-if="(currentSiteDesignChildren.length <= 0)" class="alert alert-warning shadow-lg">
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" class="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>
                <span>{{ $t("message.noSubArea") }}</span>
              </div>
            </div>
            <div class="grid grid-cols-1 gap-2 md:grid-cols-3 md:gap-2">
              <div class="card bg-base-300 hover:bg-[#191e24] text-neutral-content" v-for="(siteDesign, index) in currentSiteDesignChildren" :key="index" @click="() => { onTileClick(siteDesign.id); }">
                <div class="card-body p-5 pt-10 pb-10 flex items-center text-center">
                  <svg v-if="(siteDesign.map == null && !hasChildren(siteDesign.id))" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-12 h-12 text-dark">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z" />
                  </svg>
                  <svg v-if="siteDesign.map != null || hasChildren(siteDesign.id)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-12 h-12 text-secondary">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z" />
                  </svg>
                  <span class="text-white font-bold">{{ siteDesign.name }}</span>
                  <div v-if="(siteDesign.map == null && !hasChildren(siteDesign.id))" class="badge badge-warning">
                    <svg xmlns="http://www.w3.org/2000/svg" class="stroke-current flex-shrink-0 h-4 w-4" fill="none" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>
                    Hiányzó térkép
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { LMarker, LTileLayer, LMap } from '@vue-leaflet/vue-leaflet';
import Utils from '../misc/Utils';

export default {
  name: 'SDSelectorModal',
  props: {
    currentSiteDesign: {
      type: Object,
      default: null
    },
    onSelectSiteDesign: {
      type: Function,
      default: () => {}
    },
    changeSiteDesignFromModal: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      isModalShown: false,
      weatherData: null
    }
  },
  computed: {
    selectedSiteDesign() {
      return this.$store.getters.getSelectedSiteDesign;
    },
    siteDesigns() {
      return this.$store.getters.getSiteDesigns;
    },
    currentSiteDesignChildren() {
      if(!this.currentSiteDesign || !this.siteDesigns) {
        return [];
      }

      var children = [];
      this.siteDesigns.forEach((siteDesign) => {
        if(siteDesign.parentId == this.currentSiteDesign.id) {
          children.push(siteDesign);
        }
      });

      return children.sort(function(a, b) {
        var x = a['name']; var y = b['name'];
        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
      });
    }
  },
  components: {
    LMarker,
    LTileLayer,
    LMap
  },
  mounted() {

  },
  updated() {
    //console.log(this.currentSiteDesign)
  },
  watch: {
    currentSiteDesign() {
      if(this.currentSiteDesign) {
        this.isModalShown = true;
        this.weatherData = this.$store.getters.getWeatherData(this.currentSiteDesign.id);
      }
      else {
        this.isModalShown = false;
        this.weatherData = null;
      }
    },
    isModalShown() {
      if(!this.isModalShown) {
        this.changeSiteDesignFromModal(null);
      }
    }
  },
  methods: {
    hasChildren(siteDesignId) {
      var hasChildren = false;
      this.siteDesigns.forEach((siteDesign) => {
        if(siteDesign.parentId == siteDesignId) {
          hasChildren = true;
        }
      });

      return hasChildren;
    },
    onTileClick(siteDesignId) {
      var siteDesign = Utils.getSiteDesignById(siteDesignId);
      if(this.hasChildren(siteDesignId)) {
        this.changeSiteDesignFromModal(siteDesign)
        return;
      }
      
      var defaultRoute = "map"
      if(!siteDesign.map) {
        defaultRoute = "devices"
      }

      this.isModalShown = false;
      this.$store.commit('setSelectedSiteDesign', siteDesign);
      this.$router.push({ name: defaultRoute, params: { siteDesignId: siteDesign.id } })
    }
  }
}
</script>
