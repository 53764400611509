<template>
  <div id="MapPage" class="h-screen">
    <BaseLayout>
      <template #leftButtons>
        <BackToMapButton />
      </template>
      <template #mainContent>
        <SDBreadcrumbs v-if="(selectedSiteDesign != null)" class="absolute left-2 top-0 z-[9999]" />
        <MainMenu class="absolute top-10 left-2 z-[9999]" />
        <TemperatureMapView v-if="(selectedSiteDesign != null)" />
      </template>
    </BaseLayout>
  </div>
</template>

<script setup>
import BaseLayout from '../layouts/BaseLayout.vue'
import TemperatureMapView from './TemperatureMapPageComponents/TemperatureMapView.vue'
import MainMenu from '../components/MainMenu.vue'
import Utils from '../misc/Utils';
import BackToMapButton from '../components/BackToMapButton.vue'
import SDBreadcrumbs from '../components/SDBreadcrumbs.vue'
import { useRoute } from 'vue-router'; const route = useRoute();
import { computed, watch } from 'vue';
import store from '@/misc/vuex-store';

const selectedSiteDesign = computed(() => {
  return store.getters.getSelectedSiteDesign;
});

const siteDesigns = computed(() => {
  return store.getters.getSiteDesigns;
});

watch(() => siteDesigns.value, (newValue) => {
  if(newValue && newValue.length > 0) {
    if(!selectedSiteDesign.value && route.params.siteDesignId) { // directly linked or page refreshed
      store.commit('setSelectedSiteDesign', Utils.getSiteDesignById(route.params.siteDesignId));
    }
  }
});

</script>

<style scoped>
</style>
