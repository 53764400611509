<template>
  <div>
    <span v-if="isLoading" class="loading loading-spinner loading-xs"></span>
    <span class="prose-code" v-else>{{ $t("message.outgoingIPAddress") }}: {{ responseData }}</span>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import axios from 'axios';

export default {
  name: 'ShowOutgoingIP',
  setup() {
    const isLoading = ref(true);
    const responseData = ref('');

    onMounted(async () => {
      try {
        const response = await axios.get(process.env.VUE_APP_ADMINV2_API_URL + '/system/show-outgoing-ip');
        responseData.value = response.data;
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        isLoading.value = false;
      }
    });

    return {
      isLoading,
      responseData
    };
  }
}
</script>